import React, {useEffect} from "react";

function EmbeddedTwitter(props) {
  
  const href = `<blockquote class="twitter-tweet"><p lang="en" dir="ltr">Loading hilarious tweet... <a href="https://twitter.com/tony_besselink/status/${props.PostIdentifier}?ref_src=twsrc%5Etfw">August 4, 2020</a></blockquote>`

  function runTwitterScript() {
    window.twttr.widgets.load();
  }

  useEffect(() => {
    if (window.twttr){
      runTwitterScript();
    }
    else {
      window.addEventListener("load", () => runTwitterScript());
    }
  }, []);

  return (
     <div dangerouslySetInnerHTML={{__html: href}}></div>
  );
}
export default EmbeddedTwitter;
