import React from "react";
import AlbumTrack from "./AlbumTrack";

function AlbumTracklist(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <ol style={{ padding: "0px" }}>
        {props.songs
          ?.sort((a, b) => a.trackNumber - b.trackNumber)
          .map((song, index) => {
            return (
              <ul key={song.id}>
                <AlbumTrack
                  name={`${index + 1}. ${song.name}`}
                  credits={song.credits}
                  link={song.link}
                  lyrics={song.lyrics}
                />
              </ul>
            );
          })}
      </ol>
    </div>
  );
}

export default AlbumTracklist;
