/**
 * Poll API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PollOptionJson from './PollOptionJson';

/**
* The PollJson model module.
* @module model/PollJson
* @version v1
*/
export default class PollJson {
    /**
    * Constructs a new <code>PollJson</code>.
    * @alias module:model/PollJson
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>PollJson</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PollJson} obj Optional instance to populate.
    * @return {module:model/PollJson} The populated <code>PollJson</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PollJson();
                        
            
            if (data.hasOwnProperty('questionId')) {
                obj['questionId'] = ApiClient.convertToType(data['questionId'], 'Number');
            }
            if (data.hasOwnProperty('questionText')) {
                obj['questionText'] = ApiClient.convertToType(data['questionText'], 'String');
            }
            if (data.hasOwnProperty('exclusiveAnswers')) {
                obj['exclusiveAnswers'] = ApiClient.convertToType(data['exclusiveAnswers'], 'Boolean');
            }
            if (data.hasOwnProperty('questionUrl')) {
                obj['questionUrl'] = ApiClient.convertToType(data['questionUrl'], 'String');
            }
            if (data.hasOwnProperty('pollOptions')) {
                obj['pollOptions'] = ApiClient.convertToType(data['pollOptions'], [PollOptionJson]);
            }
        }
        return obj;
    }

    /**
    * @member {Number} questionId
    */
    'questionId' = undefined;
    /**
    * @member {String} questionText
    */
    'questionText' = undefined;
    /**
    * @member {Boolean} exclusiveAnswers
    */
    'exclusiveAnswers' = undefined;
    /**
    * @member {String} questionUrl
    */
    'questionUrl' = undefined;
    /**
    * @member {Array.<module:model/PollOptionJson>} pollOptions
    */
    'pollOptions' = undefined;




}
