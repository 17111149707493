import React, { useState, useEffect } from "react";
import HangmanImage0 from "../images/hang0.png";
import HangmanImage1 from "../images/hang1.png";
import HangmanImage2 from "../images/hang2.png";
import HangmanImage3 from "../images/hang3.png";
import HangmanImage4 from "../images/hang4.png";
import HangmanImage5 from "../images/hang5.png";
import HangmanImage6 from "../images/hang6.png";
import HangmanImage7 from "../images/hang7.png";

function SuspiciousHangmanIncorrectCounter(props) {
  const [incorrectGuessCount, setIncorrectGuessCount] = useState(props.incorrectGuessCount);

  useEffect(() => {
    setIncorrectGuessCount(props.incorrectGuessCount);
  }, [incorrectGuessCount, props.incorrectGuessCount]);


  let images = {
      0: HangmanImage0,
      1: HangmanImage1,
      2: HangmanImage2,
      3: HangmanImage3,
      4: HangmanImage4,
      5: HangmanImage5,
      6: HangmanImage6,
      7: HangmanImage7
  }
  
  return (
    <>
      
        <img width="100%" src={images[incorrectGuessCount]} alt="Your Hangman Demise!" />

        <br/>
        <div style={{textAlign:"center"}}>
          {[...Array(7).keys()].map((i) => 
              <div 
                key={i}
                style={{
                  display: "inline-block",
                  background: "#F16A08", 
                  color: "#fff",
                  fontFamily: "'Helvetica, 'Arial', 'sans-serif'",
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                  float: "inherit",
                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                  margin: "3px"

              }}>{incorrectGuessCount >= i + 1 ? "X" : ""}</div>)}
          </div>
    </>
  );
}

export default SuspiciousHangmanIncorrectCounter;
