/**
 * Video
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import VideoDto from "./DTOs/VideoDto";

/**
 * Video service.
 * @module api/VideoApi
 * @version v1
 */
export default class VideoApi {
  /**
   * Constructs a new VideoApi.
   * @alias module:api/VideoApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the createVideo operation.
   * @callback module:api/VideoApi~createVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/VideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/VideoApi~createVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/VideoDto}
   */
  createVideo(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      YoutubeVideoId: opts["youtubeVideoId"],
      Title: opts["title"],
      Description: opts["description"],
    };

    let authNames = ["Bearer"];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = VideoDto;

    return this.apiClient.callApi(
      "/api/Video",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the deleteVideo operation.
   * @callback module:api/VideoApi~deleteVideoCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/VideoApi~deleteVideoCallback} callback The callback function, accepting three arguments: error, data, response
   */
  deleteVideo(videoId, callback) {
    let postBody = null;

    let pathParams = {
      VideoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/Video/{VideoId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomVideo operation.
   * @callback module:api/VideoApi~getRandomVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/VideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/VideoApi~getRandomVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/VideoDto}
   */
  getRandomVideo(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = VideoDto;

    return this.apiClient.callApi(
      "/api/Video/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getVideo operation.
   * @callback module:api/VideoApi~getVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/VideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/VideoApi~getVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/VideoDto}
   */
  getVideo(videoId, callback) {
    let postBody = null;

    let pathParams = {
      VideoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = VideoDto;

    return this.apiClient.callApi(
      "/api/Video/{VideoId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getVideos operation.
   * @callback module:api/VideoApi~getVideosCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/VideoDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/VideoApi~getVideosCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/VideoDto>}
   */
  getVideos(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      OnlyApproved: opts["onlyApproved"],
      PageNumber: opts["pageNumber"],
      PageSize: opts["pageSize"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [VideoDto];

    return this.apiClient.callApi(
      "/api/Video",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updateVideo operation.
   * @callback module:api/VideoApi~updateVideoCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/VideoApi~updateVideoCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updateVideo(videoId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      VideoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/Video/{VideoId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
