import React, { useState, useEffect } from "react";
import FeedbackApi from "../../api/FeedbackApi";
import { Button, Alert } from "react-bootstrap";

function FeedbackRequester(props) {
  const [feedbackType, setFeedbackType] = useState("free form prose");
  const [alertBoxText, setAlertBoxText] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  useEffect(() => {
    let feedbackTypes = [
      "poem",
      "fat and skinny poem",
      "knock knock joke",
      "haiku",
      "wikipedia entry",
    ];

    let randIndex = Math.floor(Math.random() * feedbackTypes.length);
    setFeedbackType(feedbackTypes[randIndex]);
  }, []);

  function onValidateClick() {
    if (feedbackText.length === 0) {
      setAlertBoxText(`Pretty sure a ${feedbackType} needs more than 0 words`);
    } else {
      setAlertBoxText("Fuck off! Do you know how difficult that would be?");
    }
  }

  function onTextAreaChange(event) {
    setFeedbackText(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    let apiInstance = new FeedbackApi();
    apiInstance.addFeedback(
      {
        body: {
          feedbackType: feedbackType,
          feedbackComment: feedbackText,
        },
      },
      (error, data, response) => {
        if (error) {
          alert("Error submitting: " + error);
        } else {
          setAlertBoxText("Thanks for the feedback!");
        }
      }
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      {alertBoxText.length > 0 && (
        <Alert
          variant="primary"
          dismissible
          onClose={() => setAlertBoxText("")}
        >
          {alertBoxText}
        </Alert>
      )}

      <p>
        <b>Please provide site feedback in the form of a {feedbackType}:</b>
      </p>
      <textarea
        style={{ width: "100%" }}
        value={feedbackText}
        onChange={onTextAreaChange}
      />
      <Button onClick={onValidateClick}>
        Check if {feedbackType} is valid
      </Button>
      <Button onClick={onSubmit}>Submit feedback</Button>
    </div>
  );
}

export default FeedbackRequester;
