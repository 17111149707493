import React from "react";
import { Row, Col } from "react-bootstrap";
import JacobImage from "../images/bandMember-Jacob.png";
import RhysImage from "../images/bandMember-Rhys.png";
import NickImage from "../images/bandMember-Nick.jpg";
import TomImage from "../images/bandMember-Tom.jpg";
import TonyImage from "../images/bandMember-Tony.jpg";

function BandMembers() {
  return (
    <>
      <div class="side_panel_box_centered">
        <h1>Members</h1>
        <br />
        <br />
      </div>

      <div class="member-info">
        <Row>
          <Col md={4}>
            <img width="100%" src={JacobImage} alt="Jacob" />
          </Col>

          <Col md={8}>
            <div>
              <h2>Jacob Thaw</h2>
              <h4>(Keyboards)</h4>
              <p>
                A unique talent that only few possess, Jacob can play it all.
                His fingers can move so quickly and precisely over the keys that
                to the the human eye they&#39;re just a blur. But when he needs
                to he can slow down and soothe, too. Caressing his long
                instrument like a skilled lover, he brings pleasure to all who
                get to experience his gift.
              </p>
            </div>
          </Col>
        </Row>
        <hr />
      </div>

      <div class="member-info">
        <Row>
          <Col md={{ span: 4, order: "last" }} class="col-md-4 col-md-push-8">
            <img width="100%" src={RhysImage} alt="Rhys" />
          </Col>

          <Col md={8} style={{ textAlign: "right" }}>
            <div>
              <h2>Rhys Brown</h2>
              <h4>(Drums)</h4>
              <p>
                Of the many men in Tony Besselink, the one who holds the best
                rhythm is Rhys Brown. He had to exit Tony Besselink briefly
                (where another man entered Tony Besselink to fill his place),
                but now Rhys Brown is back in Tony Besselink. All the practice
                he has put in has made Tony Besselink really tight, and no one
                can bring Tony Besselink to a climax like he does, his powerful
                drumming carving out the perfect crescendo.{" "}
              </p>
            </div>
          </Col>
        </Row>
        <hr />
      </div>

      <div class="member-info">
        <Row>
          <Col md={4}>
            <img width="100%" src={NickImage} alt="Nick" />
          </Col>

          <Col md={8}>
            <div>
              <h2>Nick Smith</h2>
              <h4>(Bass)</h4>
              <p>
                Nick Smith was born in Liverpool, England in 1940. Raised mostly
                by his Aunt Mimi, Nick was heavily influenced by such novelty
                music performers like &quot;Elvis&quot; and &quot;The
                Quarrymen.&quot; In highschool he met Tony Besselink, and the
                two started a band called, &quot;The Insects&quot;, which later
                evolved into Tony Besselink (The Band). Now Nick brings his
                strange girlfriend to all the band meetings, and, to be honest
                with you, she&#39;s really kind of ruining it and I think the
                band might just split up.{" "}
              </p>
            </div>
          </Col>
        </Row>
        <hr />
      </div>

      <div class="member-info">
        <Row>
          <Col md={{ span: 4, order: "last" }}>
            <img width="100%" src={TomImage} alt="Tom" />
          </Col>

          <Col md={8} style={{ textAlign: "right" }}>
            <div>
              <h2>Tom Needham</h2>
              <h4>(Guitar)</h4>
              <p>Tom is the guitarist in Tony Besselink.</p>
            </div>
          </Col>
        </Row>
        <hr />
      </div>

      <div class="member-info">
        <Row>
          <Col md={4}>
            <img width="100%" src={TonyImage} alt="Tony" />
          </Col>

          <Col md={8}>
            <div>
              <h2>Tony Besselink</h2>
              <h4>(Lead &quot;Singer&quot;, Fiddler, Theremin Thruster)</h4>
              <p>
                Last and least is Tony Besselink. Despite what many people
                think, the band was not named after him. In fact, he was named
                after the band. The least talented member of the group, he&#39;s
                very lucky that the other members haven&#39;t followed through
                with their suggestion of kicking him out and continuing with
                Tony Besselink (The Band) on their own. He is also the first
                person ever on Youtube to play a theremin by fucking it.
              </p>
            </div>
          </Col>
        </Row>
        <hr />
      </div>
    </>
  );
}

export default BandMembers;
