import React from "react";
import AlbumTracklist from "./AlbumTracklist";

function Album(props) {
  const imgStyle = {
    marginBottom: "20px",
    border: "3px solid #89C0EB",
    borderRadius: "17px 17px 17px 17px",
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "15px" }}>
        <img
          className="box-shadow--8dp"
          style={imgStyle}
          src={props.albumCoverUrl}
          alt={props.name}
          width="100%"
        />
        <h2 style={{ margin: "0px" }}>{props.name}</h2>
        <em style={{ color: "#AAA" }}>{props.releaseDate.toDateString()}</em>
      </div>

      <AlbumTracklist songs={props.songs} />
      <hr></hr>
    </>
  );
}

export default Album;
