import React, { useState } from "react";
import FeedbackApi from "../api/FeedbackApi";
import { Button, Alert } from "react-bootstrap";

function ContactMePage(props) {
  const [alertBoxText, setAlertBoxText] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);

  function onTextAreaChange(event) {
    setFeedbackText(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    let apiInstance = new FeedbackApi();
    apiInstance.addFeedback(
      {
        body: {
          feedbackType: "free form prose",
          feedbackComment: feedbackText,
        },
      },
      (error, data, response) => {
        if (error) {
          alert("Error submitting: " + error);
        } else {
          setAlertBoxText("Thanks for the feedback!");
          setFeedbackSent(true);
        }
      }
    );
  }

  return (
    <>
      <h1>You wish to contact me?</h1>
      <p>
        Do you want to give me compliments? Share a sonnet you've written about
        me? Tell me about some delightful bug you've found that made your day?
      </p>
      <p>Well, just chuck it in this text box below and hit send.</p>
      <textarea
        style={{ width: "100%" }}
        value={feedbackText}
        onChange={onTextAreaChange}
      />
      <div style={{ textAlign: "center" }}>
        <Button onClick={onSubmit} disabled={feedbackSent}>
          Submit
        </Button>
        {alertBoxText.length > 0 && (
          <Alert variant="primary" onClose={() => setAlertBoxText("")}>
            {alertBoxText}
          </Alert>
        )}
      </div>
    </>
  );
}

export default ContactMePage;
