import "./App.css";
import {useState} from "react";
import HomePage from "./components/HomePage";
import Header from "./components/common/Header";
import BandStory from "./components/Band/BandStory";
import BandMembers from "./components/Band/BandMembers";
import MusicVideoPage from "./components/Band/MusicVideoPage";
import ContactMePage from "./components/ContactMePage";
import AlbumPage from "./components/Band/AlbumPage";
import PodcastPage from "./components/Podcast/PodcastPage";
import BlogPage from "./components/Blog/BlogPage";
import FictionPage from "./components/Fiction/FictionPage";
import SuspiciousHangman from "./components/SuspiciousHangman/SuspiciousHangman";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Switch } from "react-router-dom";
import {matchPath, useLocation} from "react-router";

import RandomSideComponent from "./components/SideComponents/RandomSideComponent";


function App() {
  const [predeterminedSidePanels, setPredeterminedSidePanels] = useState([]);

  let location = useLocation();

  const contentBox = {
    padding: "1em" /*Keeps the text from being jammed in the top left corner.*/,
    marginBottom:
      "1.2em" /*Gives a little bit of space at the bottom of the page.*/,
    borderRadius: "17px",
    fontFamily: "Arial, Helvetica, sans-serif",
    boxShadow:
      "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
  };

  const whiteBackground = { background: "#FFF" };
  const yellowBackground = { background: "#fff1ad" };

  function contextualSideBox(boxNumber) {
    if (!predeterminedSidePanels[boxNumber]) {
      return <RandomSideComponent />
    }

    let pathForPredeterminedBox = predeterminedSidePanels[boxNumber].path;

    if (!matchPath(location.pathname, {path: pathForPredeterminedBox})) {
      return <RandomSideComponent />
    }

    return predeterminedSidePanels[boxNumber].component;
  }


  return (
    <>
      <Header />
      <Container fluid="md">
        <Row className="justify-content-md-center">
          <Col lg={8}>
            <div style={{ ...contentBox, ...whiteBackground }}>
              <Switch>
                <Route path="/" exact render={() => <HomePage />} />
                <Route path="/blog/:blogId?" render={(props) => <BlogPage setPredeterminedSidePanels={setPredeterminedSidePanels} {...props} />} />
                <Route path="/fiction/:fictionId?" component={FictionPage} />
                <Route path="/podcast" render={() => <PodcastPage />} />
                <Route path="/band/story" render={() => <BandStory />} />
                <Route path="/band/members" render={() => <BandMembers />} />
                <Route path="/band/albums" render={() => <AlbumPage />} />
                <Route path="/band/live" render={() => <MusicVideoPage />} />
                <Route path="/contact" render={() => <ContactMePage />} />
                <Route path="/suspicioushangman" render={() => <SuspiciousHangman />} />
                {/* <Route component={NotFoundComponent} /> */}
              </Switch>
            </div>
          </Col>
          <Col>
            <div style={{ ...contentBox, ...yellowBackground }}>
              {contextualSideBox(0)}
            </div>
            <div style={{ ...contentBox, ...yellowBackground }}>
              {contextualSideBox(1)}
            </div>
            <div style={{ ...contentBox, ...yellowBackground }}>
              {contextualSideBox(2)}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
