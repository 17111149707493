/**
 * Podcast API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The EpisodeDto model module.
* @module model/EpisodeDto
* @version v1
*/
export default class EpisodeDto {
    /**
    * Constructs a new <code>EpisodeDto</code>.
    * @alias module:model/EpisodeDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>EpisodeDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/EpisodeDto} obj Optional instance to populate.
    * @return {module:model/EpisodeDto} The populated <code>EpisodeDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new EpisodeDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('numberInSeason')) {
                obj['numberInSeason'] = ApiClient.convertToType(data['numberInSeason'], 'Number');
            }
            if (data.hasOwnProperty('publishedDate')) {
                obj['publishedDate'] = ApiClient.convertToType(data['publishedDate'], 'Date');
            }
            if (data.hasOwnProperty('showName')) {
                obj['showName'] = ApiClient.convertToType(data['showName'], 'String');
            }
            if (data.hasOwnProperty('showId')) {
                obj['showId'] = ApiClient.convertToType(data['showId'], 'Number');
            }
            if (data.hasOwnProperty('showUrl')) {
                obj['showUrl'] = ApiClient.convertToType(data['showUrl'], 'String');
            }
            if (data.hasOwnProperty('seasonName')) {
                obj['seasonName'] = ApiClient.convertToType(data['seasonName'], 'String');
            }
            if (data.hasOwnProperty('downloadLink')) {
                obj['downloadLink'] = ApiClient.convertToType(data['downloadLink'], 'String');
            }
            if (data.hasOwnProperty('webPageLink')) {
                obj['webPageLink'] = ApiClient.convertToType(data['webPageLink'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {String} description
    */
    'description' = undefined;
    /**
    * @member {Number} numberInSeason
    */
    'numberInSeason' = undefined;
    /**
    * @member {Date} publishedDate
    */
    'publishedDate' = undefined;
    /**
    * @member {String} showName
    */
    'showName' = undefined;
    /**
    * @member {Number} showId
    */
    'showId' = undefined;
    /**
    * @member {String} showUrl
    */
    'showUrl' = undefined;
    /**
    * @member {String} seasonName
    */
    'seasonName' = undefined;
    /**
    * @member {String} downloadLink
    */
    'downloadLink' = undefined;
    /**
    * @member {String} webPageLink
    */
    'webPageLink' = undefined;




}
