/**
 * Blog API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The BlogDto model module.
* @module model/BlogDto
* @version v1
*/
export default class BlogDto {
    /**
    * Constructs a new <code>BlogDto</code>.
    * @alias module:model/BlogDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>BlogDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/BlogDto} obj Optional instance to populate.
    * @return {module:model/BlogDto} The populated <code>BlogDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BlogDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('publishedDate')) {
                obj['publishedDate'] = ApiClient.convertToType(data['publishedDate'], 'Date');
            }
            if (data.hasOwnProperty('published')) {
                obj['published'] = ApiClient.convertToType(data['published'], 'Boolean');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} title
    */
    'title' = undefined;
    /**
    * @member {Date} publishedDate
    */
    'publishedDate' = undefined;
    /**
    * @member {Boolean} published
    */
    'published' = undefined;
    /**
    * @member {String} content
    */
    'content' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;




}
