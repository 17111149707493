import React, { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import PollApi from "../../../api/PollsApi";

function PollCastVote(props) {
  const [moreInfoText, setMoreInfoText] = useState("");
  const [showElaborationBox, setShowElaborationBox] = useState(false);
  const [elaborationText, setElaborationText] = useState("");
  const [selectedPollOption, setSelectedPollOption] = useState(-1);

  function onRadioClick(
    showElaborationBox,
    moreInfoText,
    newSelectedPollOption
  ) {
    setShowElaborationBox(showElaborationBox);
    if (!showElaborationBox) {
      setElaborationText("");
    }
    setMoreInfoText(moreInfoText ?? "");
    setSelectedPollOption(newSelectedPollOption);
  }

  function onTextAreaChange(event) {
    setElaborationText(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    let apiInstance = new PollApi();
    apiInstance.addVote(
      {
        body: [
          {
            choiceId: selectedPollOption,
            reason: elaborationText.length > 0 ? elaborationText : null,
          },
        ],
      },
      (error, data, response) => {
        if (error) {
          alert("Error submitting: " + error);
        } else {
          setMoreInfoText("Thanks for voting!");
          props.onVote(data);
        }
      }
    );
  }

  return (
    <>
      {moreInfoText.length > 0 && (
        <Alert variant="primary">{moreInfoText}</Alert>
      )}
      <div>
        {props.pollOptions?.map((pollOption) => {
          return (
            <React.Fragment key={pollOption.pollOptionId}>

              <label 
                htmlFor={`Poll_${pollOption.pollOptionId}`} 
                onClick={() =>
                  onRadioClick(
                    pollOption.canExplainChoice,
                    pollOption.moreInformation,
                    pollOption.pollOptionId
                  )
                }>
                <input
                  key={pollOption.pollOptionId}
                  type="Radio"
                  name={`Poll_${pollOption.pollOptionId}`}
                  value={pollOption.pollOptionId}
                  style={{ marginRight: "7px" }}
                  onChange={() => {}}
                  checked={pollOption.pollOptionId === selectedPollOption}
                />
                {pollOption.pollText}
              </label>
              <br />
            </React.Fragment>
          );
        })}

        {showElaborationBox && (
          <div style={{ textAlign: "center" }}>
            <em>(Optional) Care to elaborate?</em>
            <br />
            <textarea
              style={{ width: "100%" }}
              value={elaborationText}
              onChange={onTextAreaChange}
            />
          </div>
        )}

        <div style={{ textAlign: "center" }}>
          <Button
            as="input"
            type="Submit"
            value="Vote"
            onClick={onSubmit}
            disabled={props.hasVoted}
            readOnly
            style={{ margin: "5px" }}
          />
          <Button onClick={props.onChangeView} style={{ margin: "5px" }}>
            Show Results
          </Button>
        </div>
      </div>
    </>
  );
}

export default PollCastVote;
