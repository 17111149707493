/*
 * SuspiciousHangman
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The HangmanGuess model module.
 * @module model/HangmanGuess
 * @version v1
 */
export class HangmanGuess {
  /**
   * Constructs a new <code>HangmanGuess</code>.
   * @alias module:model/HangmanGuess
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>HangmanGuess</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HangmanGuess} obj Optional instance to populate.
   * @return {module:model/HangmanGuess} The populated <code>HangmanGuess</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HangmanGuess();
      if (data.hasOwnProperty('wordLength'))
        obj.wordLength = ApiClient.convertToType(data['wordLength'], 'Number');
      if (data.hasOwnProperty('wrongGuesses'))
        obj.wrongGuesses = ApiClient.convertToType(data['wrongGuesses'], ['String']);
      if (data.hasOwnProperty('correctGuesses'))
        obj.correctGuesses = ApiClient.convertToType(data['correctGuesses'], {'String': ['Number']});
      if (data.hasOwnProperty('guessedLetter'))
        obj.guessedLetter = ApiClient.convertToType(data['guessedLetter'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Number} wordLength
 */
HangmanGuess.prototype.wordLength = undefined;

/**
 * @member {Array.<String>} wrongGuesses
 */
HangmanGuess.prototype.wrongGuesses = undefined;

/**
 * @member {Object.<String, Array.<Number>>} correctGuesses
 */
HangmanGuess.prototype.correctGuesses = undefined;

/**
 * @member {String} guessedLetter
 */
HangmanGuess.prototype.guessedLetter = undefined;

