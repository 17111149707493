import React from "react";
import IntroImage from "./images/intro-image.jpg";

function HomePage() {
  return (
    <>
      <h2>Oh, hello there...</h2>

      <br />

      <div id="intro_panel">
        <div id="intro_picture">
          <img
            style={{ float: "left", marginRight: "16px" }}
            width="47%"
            alt="Me looking odd."
            src={IntroImage}
          />
        </div>
        <div id="intro_words">
          <p>
            It's so nice of you to pop by! I was just doing some tidying up.
            Please, come in. I've got lots of content for you to enjoy. Come and
            explore!
          </p>

          <p>
            No? You're content to just linger here in the entrance way, are you?
            You don't want to read any blogs? I think they're rather amusing
            myself. Or I've got some stories for you to read. No, still not
            moving.
          </p>

          <p>
            Ah, I know what you want, Podcasts, there's a lot of them in the
            "Podcast" tab. Why don't you click on that tab and see what's there?
            Look, you're making me nervous, just loitering at the index page.
            This page wasn't meant to be dwelt on, you know. Go on, just move
            your cursor over to the "Podcast", move it... go on, move it... move
            your fricken mouse over the damn tab and click it!
          </p>

          <p>
            Christ! What is it you want! You interested in The Band? Ok then,
            click on that tab. Just click fucking something! Jeeze, what the
            hell is wrong with you? Look, I've got random shit off to the right
            of the screen for you to play with. I don't know what it is at the
            moment, it's all random. Please, dear god, just do something.
          </p>

          <p>
            You're like a bloody cat, waiting at a door trying to make up its
            mind if it wants to come inside. Well, this paragraph is about to
            end. Are you coming in or not?
          </p>
        </div>
      </div>
    </>
  );
}

export default HomePage;
