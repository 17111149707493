/**
 * Tony's Music
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import SongDto from "./SongDto";

/**
 * The AlbumWithSongsDto model module.
 * @module model/AlbumWithSongsDto
 * @version v1
 */
export default class AlbumWithSongsDto {
  /**
   * Constructs a new <code>AlbumWithSongsDto</code>.
   * @alias module:model/AlbumWithSongsDto
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>AlbumWithSongsDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AlbumWithSongsDto} obj Optional instance to populate.
   * @return {module:model/AlbumWithSongsDto} The populated <code>AlbumWithSongsDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new AlbumWithSongsDto();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("url")) {
        obj["url"] = ApiClient.convertToType(data["url"], "String");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("releaseDate")) {
        obj["releaseDate"] = ApiClient.convertToType(
          data["releaseDate"],
          "Date"
        );
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
      if (data.hasOwnProperty("displayNumber")) {
        obj["displayNumber"] = ApiClient.convertToType(
          data["displayNumber"],
          "Number"
        );
      }
      if (data.hasOwnProperty("albumCoverUrl")) {
        obj["albumCoverUrl"] = ApiClient.convertToType(
          data["albumCoverUrl"],
          "String"
        );
      }
      if (data.hasOwnProperty("songs")) {
        obj["songs"] = ApiClient.convertToType(data["songs"], [SongDto]);
      }
    }
    return obj;
  }

  /**
   * @member {Number} id
   */
  id = undefined;
  /**
   * @member {String} url
   */
  url = undefined;
  /**
   * @member {String} name
   */
  name = undefined;
  /**
   * @member {Date} releaseDate
   */
  releaseDate = undefined;
  /**
   * @member {String} description
   */
  description = undefined;
  /**
   * @member {Number} displayNumber
   */
  displayNumber = undefined;
  /**
   * @member {String} albumCoverUrl
   */
  albumCoverUrl = undefined;
  /**
   * @member {Array.<module:model/SongDto>} songs
   */
  songs = undefined;
}
