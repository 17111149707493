/**
 * Podcast API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The ShowDto model module.
* @module model/ShowDto
* @version v1
*/
export default class ShowDto {
    /**
    * Constructs a new <code>ShowDto</code>.
    * @alias module:model/ShowDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ShowDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ShowDto} obj Optional instance to populate.
    * @return {module:model/ShowDto} The populated <code>ShowDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShowDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('displayNumber')) {
                obj['displayNumber'] = ApiClient.convertToType(data['displayNumber'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {String} description
    */
    'description' = undefined;
    /**
    * @member {Number} displayNumber
    */
    'displayNumber' = undefined;




}
