import React, { useState, useEffect } from "react";
import PollCastVote from "./PollCastVote";
import PollResults from "./PollResults";

function Poll(props) {
  const [hasVoted, setHasVoted] = useState(false);
  const [pollOptions, setPollOptions] = useState(props.pollOptions);
  const [pollView, setPollView] = useState("CastVote");

  function onVote(updatedQuestion) {
    setHasVoted(true);
    setPollOptions(updatedQuestion.pollOptions);
  }

  function onChangeView() {
    setPollView(pollView === "CastVote" ? "ShowResults" : "CastVote");
  }

  useEffect(() => {
    setPollOptions(props.pollOptions);
  }, [props.pollOptions]);

  return (
    <>
      <h5>
        <b>Q. {props.questionText}</b>
      </h5>

      {pollView === "CastVote" ? (
        <PollCastVote
          pollOptions={pollOptions}
          questionId={props.questionId}
          hasVoted={hasVoted}
          onVote={onVote}
          onChangeView={onChangeView}
        />
      ) : (
        <PollResults pollOptions={pollOptions} onChangeView={onChangeView} />
      )}
    </>
  );
}

export default Poll;
