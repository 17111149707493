import React from "react";
import ReactMarkdown from "react-markdown";

function Fiction(props) {
  return (
    <>
      <h1>{props.title}</h1>
      <p style={{ color: "#999", fontSize: "14px" }}>
        <em>Published {props.createdDate.toDateString()}</em>
      </p>
      <div>
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </div>
    </>
  );
}

export default Fiction;
