import React, { useRef, useState } from "react";
import { Button, ProgressBar, Modal } from "react-bootstrap";

function PollResults(props) {
  const DEFAULT_EMPTY_REASON_MESSAGE =
    "No one has provided a reason for this choice yet.";
  const [modalValues, setModalValues] = useState({
    show: false,
    title: "",
    reasons: [
      { userSubmittedReasonId: 0, reasonText: DEFAULT_EMPTY_REASON_MESSAGE },
    ],
  });

  const totalVotes = useRef(-1);
  if (totalVotes.current < 0) {
    totalVotes.current = props.pollOptions.reduce(
      (countedVotes, nextPollOption) =>
        countedVotes + nextPollOption.numberOfVotes,
      0
    );
  }

  function ShowResults(questionText, reasonArray) {
    if (reasonArray.length === 0) {
      reasonArray = [
        { userSubmittedReasonId: 0, reasonText: DEFAULT_EMPTY_REASON_MESSAGE },
      ];
    }
    setModalValues({ show: true, title: questionText, reasons: reasonArray });
  }

  function CloseModal() {
    setModalValues({ ...modalValues, show: false });
  }

  return (
    <>
      {props.pollOptions?.map((pollOption) => {
        let votePercentage =
          totalVotes.current > 0
            ? (pollOption.numberOfVotes / parseFloat(totalVotes.current)) * 100
            : 0;
        votePercentage = votePercentage.toFixed(0);
        return (
          <div
            key={pollOption.pollOptionId}
            onClick={() =>
              ShowResults(pollOption.pollText, pollOption.userSubmittedReasons)
            }
          >
            {pollOption.pollText} -{" "}
            <em>
              (
              {`${pollOption.numberOfVotes} ${
                pollOption.NumberOfVotes === 1 ? "Vote" : "Votes"
              }`}
              )
            </em>
            <br />
            <ProgressBar now={votePercentage} label={`${votePercentage}%`} />
            <br />
          </div>
        );
      })}

      <div style={{ textAlign: "center" }}>
        <em>(Click a choice to see people's reasoning)</em>
        <br />
        <Button onClick={props.onChangeView}>Back To Poll</Button>
      </div>

      <Modal show={modalValues.show} onHide={CloseModal}>
        <Modal.Header>
          <Modal.Title>
            Why people voted for <em>{modalValues.title}</em>:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {modalValues.reasons.map((reason) => {
              return (
                <li key={reason.userSubmittedReasonId}>{reason.reasonText}</li>
              );
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={CloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PollResults;
