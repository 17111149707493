/**
 * Podcast API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import EpisodeDto from "./DTOs/EpisodeDto";
import EpisodeForCreationDto from "./DTOs/EpisodeForCreationDto";

/**
 * PodcastEpisode service.
 * @module api/PodcastEpisodeApi
 * @version v1
 */
export default class PodcastEpisodeApi {
  /**
   * Constructs a new PodcastEpisodeApi.
   * @alias module:api/PodcastEpisodeApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addPodcastEpisode operation.
   * @callback module:api/PodcastEpisodeApi~addPodcastEpisodeCallback
   * @param {String} error Error message, if any.
   * @param {module:model/EpisodeDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastEpisodeApi~addPodcastEpisodeCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/EpisodeDto}
   */
  addPodcastEpisode(showId, seasonId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      showId: showId,
      seasonId: seasonId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = EpisodeDto;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}/seasons/{seasonId}/episodes",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPodcastEpisode operation.
   * @callback module:api/PodcastEpisodeApi~getPodcastEpisodeCallback
   * @param {String} error Error message, if any.
   * @param {module:model/EpisodeDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PodcastEpisodeApi~getPodcastEpisodeCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/EpisodeDto}
   */
  getPodcastEpisode(showId, seasonId, episodeId, callback) {
    let postBody = null;

    let pathParams = {
      showId: showId,
      seasonId: seasonId,
      episodeId: episodeId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = EpisodeDto;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}/seasons/{seasonId}/episodes/{episodeId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPodcastEpisodes operation.
   * @callback module:api/PodcastEpisodeApi~getPodcastEpisodesCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/EpisodeDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastEpisodeApi~getPodcastEpisodesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/EpisodeDto>}
   */
  getPodcastEpisodes(showId, seasonId, opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {
      showId: showId,
      seasonId: seasonId,
    };
    let queryParams = {
      PageNumber: opts["pageNumber"],
      PageSize: opts["pageSize"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [EpisodeDto];

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}/seasons/{seasonId}/episodes",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomPodcastEpisode operation.
   * @callback module:api/PodcastEpisodeApi~getRandomPodcastEpisodeCallback
   * @param {String} error Error message, if any.
   * @param {module:model/EpisodeDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PodcastEpisodeApi~getRandomPodcastEpisodeCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/EpisodeDto}
   */
  getRandomPodcastEpisode(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = EpisodeDto;

    return this.apiClient.callApi(
      "/api/podcast/episodes/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the removePodcastEpisode operation.
   * @callback module:api/PodcastEpisodeApi~removePodcastEpisodeCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PodcastEpisodeApi~removePodcastEpisodeCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removePodcastEpisode(showId, seasonId, episodeId, callback) {
    let postBody = null;

    let pathParams = {
      showId: showId,
      seasonId: seasonId,
      episodeId: episodeId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}/seasons/{seasonId}/episodes/{episodeId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updatePodcastEpisode operation.
   * @callback module:api/PodcastEpisodeApi~updatePodcastEpisodeCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastEpisodeApi~updatePodcastEpisodeCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updatePodcastEpisode(showId, seasonId, episodeId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      showId: showId,
      seasonId: seasonId,
      episodeId: episodeId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}/seasons/{seasonId}/episodes/{episodeId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
