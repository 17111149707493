import React, { useState } from "react";
import { Collapse, Button } from "react-bootstrap";

function AlbumTrack(props) {
  const [expandLyrics, setExpandLyrics] = useState(false);

  return (
    <>
      <h4 style={{ display: "inline-block" }}>{props.name}</h4> (
      <Button
        style={{ padding: "0px" }}
        variant="link"
        onClick={() => setExpandLyrics(!expandLyrics)}
      >
        Listen
      </Button>
      )
      <Collapse in={expandLyrics}>
        <div>
          <div dangerouslySetInnerHTML={{__html:props.link}}></div>
          <em style={{ color: "#AAA" }}>({props.credits})</em>
          <p style={{ whiteSpace: "pre-line" }}>{props.lyrics}</p>
        </div>
      </Collapse>
    </>
  );
}

export default AlbumTrack;
