/**
 * Video
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The VideoDto model module.
 * @module model/VideoDto
 * @version v1
 */
export default class VideoDto {
  /**
   * Constructs a new <code>VideoDto</code>.
   * @alias module:model/VideoDto
   * @class
   */

  constructor() {}

  /**
   * Constructs a <code>VideoDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VideoDto} obj Optional instance to populate.
   * @return {module:model/VideoDto} The populated <code>VideoDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new VideoDto();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("url")) {
        obj["url"] = ApiClient.convertToType(data["url"], "String");
      }
      if (data.hasOwnProperty("youtubeVideoId")) {
        obj["youtubeVideoId"] = ApiClient.convertToType(
          data["youtubeVideoId"],
          "String"
        );
      }
      if (data.hasOwnProperty("title")) {
        obj["title"] = ApiClient.convertToType(data["title"], "String");
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
    }
    return obj;
  }

  /**
   * @member {Number} id
   */
  id = undefined;
  /**
   * @member {String} url
   */
  url = undefined;
  /**
   * @member {String} youtubeVideoId
   */
  youtubeVideoId = undefined;
  /**
   * @member {String} title
   */
  title = undefined;
  /**
   * @member {String} description
   */
  description = undefined;
}
