/*
 * SuspiciousHangman
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

/**
 * The HangmanResponse model module.
 * @module model/HangmanResponse
 * @version v1
 */
export class HangmanResponse {
  /**
   * Constructs a new <code>HangmanResponse</code>.
   * @alias module:model/HangmanResponse
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>HangmanResponse</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/HangmanResponse} obj Optional instance to populate.
   * @return {module:model/HangmanResponse} The populated <code>HangmanResponse</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new HangmanResponse();
      if (data.hasOwnProperty('guessedLetter'))
        obj.guessedLetter = ApiClient.convertToType(data['guessedLetter'], 'String');
      if (data.hasOwnProperty('correctGuess'))
        obj.correctGuess = ApiClient.convertToType(data['correctGuess'], 'Boolean');
      if (data.hasOwnProperty('correctGuessPositions'))
        obj.correctGuessPositions = ApiClient.convertToType(data['correctGuessPositions'], ['Number']);
      if (data.hasOwnProperty('gameOverWord'))
        obj.gameOverWord = ApiClient.convertToType(data['gameOverWord'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} guessedLetter
 */
HangmanResponse.prototype.guessedLetter = undefined;

/**
 * @member {Boolean} correctGuess
 */
HangmanResponse.prototype.correctGuess = undefined;

/**
 * @member {Array.<Number>} correctGuessPositions
 */
HangmanResponse.prototype.correctGuessPositions = undefined;

/**
 * @member {String} gameOverWord
 */
HangmanResponse.prototype.gameOverWord = undefined;

