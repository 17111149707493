/**
 * Tony's Music
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import LiveVideoDto from "./DTOs/LiveVideoDto";

/**
 * LiveVideo service.
 * @module api/LiveVideoApi
 * @version v1
 */
export default class LiveVideoApi {
  /**
   * Constructs a new LiveVideoApi.
   * @alias module:api/LiveVideoApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addLiveVideo operation.
   * @callback module:api/LiveVideoApi~addLiveVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LiveVideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/LiveVideoApi~addLiveVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LiveVideoDto}
   */
  addLiveVideo(songId, opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {
      songId: songId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      VideoTitle: opts["videoTitle"],
      Description: opts["description"],
      PerformanceDate: opts["performanceDate"],
      YouTubeLink: opts["youTubeLink"],
      DisplayOrderNumber: opts["displayOrderNumber"],
    };

    let authNames = ["Bearer"];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = LiveVideoDto;

    return this.apiClient.callApi(
      "/api/music/songs/{songId}/videos",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getLiveVideo operation.
   * @callback module:api/LiveVideoApi~getLiveVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LiveVideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/LiveVideoApi~getLiveVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LiveVideoDto}
   */
  getLiveVideo(songId, videoId, callback) {
    let postBody = null;

    let pathParams = {
      songId: songId,
      videoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = LiveVideoDto;

    return this.apiClient.callApi(
      "/api/music/songs/{songId}/videos/{videoId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getLiveVideos operation.
   * @callback module:api/LiveVideoApi~getLiveVideosCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/LiveVideoDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/LiveVideoApi~getLiveVideosCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/LiveVideoDto>}
   */
  getLiveVideos(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [LiveVideoDto];

    return this.apiClient.callApi(
      "/api/music/videos",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomLiveVideo operation.
   * @callback module:api/LiveVideoApi~getRandomLiveVideoCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LiveVideoDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/LiveVideoApi~getRandomLiveVideoCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LiveVideoDto}
   */
  getRandomLiveVideo(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = LiveVideoDto;

    return this.apiClient.callApi(
      "/api/music/videos/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the removeLiveVideo operation.
   * @callback module:api/LiveVideoApi~removeLiveVideoCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/LiveVideoApi~removeLiveVideoCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeLiveVideo(songId, videoId, callback) {
    let postBody = null;

    let pathParams = {
      songId: songId,
      videoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/music/songs/{songId}/videos/{videoId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updateLiveVideo operation.
   * @callback module:api/LiveVideoApi~updateLiveVideoCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/LiveVideoApi~updateLiveVideoCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updateLiveVideo(songId, videoId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      songId: songId,
      videoId: videoId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/music/songs/{songId}/videos/{videoId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
