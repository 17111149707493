import React, { useState, useEffect } from "react";
import FeedbackApi from "../../api/FeedbackApi";

function Feedback(props) {
  const [feedback, setFeedback] = useState({});

  useEffect(() => {
    let apiInstance = new FeedbackApi();
    apiInstance.getRandomFeedback((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setFeedback(data);
      }
    });
  }, []);

  return (
    <>
      <p>
        <b>
          On {feedback?.dateCreated?.toDateString()}, a very lovely person wrote
          a {feedback?.feedbackType} about this website:
        </b>
      </p>
      <p style={{ whiteSpace: "pre-line" }}>{feedback?.feedbackComment}</p>
    </>
  );
}

export default Feedback;
