import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import PodcastShowApi from "../../api/PodcastShowApi";
import PodcastShow from "./PodcastShow";

function PodcastPage() {
  const [podcastShows, setPodcastShows] = useState([]);

  useEffect(() => {
    let apiInstance = new PodcastShowApi();
    apiInstance.getPodcastShows(null, (error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setPodcastShows(data);
      }
    });
  }, []);

  return (
    <>
      <p>
        Here you can find a list of podcasts I had a hand in creating.
      </p>
      <Accordion>
        {podcastShows
          .sort((a, b) => b.displayNumber - a.displayNumber)
          .map((show) => {
            return (
              <PodcastShow
                key={show.id}
                id={show.id}
                name={show.name}
                description={show.description}
              />
            );
          })}
      </Accordion>
    </>
  );
}

export default PodcastPage;
