import React from "react";

function SuspiciousHangmanWordDisplay(props) {


  function formatDisplay() {
    if (props.winningWord !== ""){
      return props.winningWord.split("").join(" "); // return the winning word with spaces in between
    }

    let mappedCorrectLetters = {};
    for (let letter in props.correctLetters){
      let positionsOfLetter = props.correctLetters[letter];

      positionsOfLetter.forEach((positionOfLetter) => {
        mappedCorrectLetters[positionOfLetter] = letter;
      })
    }

    let letters = [];
    for (let i = 1; i <= props.wordLength; i++){
      letters.push(mappedCorrectLetters[i] ?? "_");
    }

    return letters.join(" ");
  }

  return (
    <div>
      <svg viewBox="0 0 175 18">
        <text x="50%" y="75%" textAnchor="middle">{formatDisplay()}</text>
      </svg>
    </div>
  );
}

export default SuspiciousHangmanWordDisplay;
