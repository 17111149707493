/**
 * Tony's Music
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import AlbumDto from "./DTOs/AlbumDto";
import AlbumWithSongsDto from "./DTOs/AlbumWithSongsDto";

/**
 * Album service.
 * @module api/AlbumApi
 * @version v1
 */
export default class AlbumApi {
  /**
   * Constructs a new AlbumApi.
   * @alias module:api/AlbumApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addAlbum operation.
   * @callback module:api/AlbumApi~addAlbumCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AlbumDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/AlbumApi~addAlbumCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AlbumDto}
   */
  addAlbum(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      AlbumCover: opts["albumCover"],
      Name: opts["name"],
      ReleaseDate: opts["releaseDate"],
      Description: opts["description"],
      DisplayNumber: opts["displayNumber"],
    };

    let authNames = ["Bearer"];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = AlbumDto;

    return this.apiClient.callApi(
      "/api/music/albums",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getAlbum operation.
   * @callback module:api/AlbumApi~getAlbumCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AlbumDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/AlbumApi~getAlbumCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AlbumDto}
   */
  getAlbum(albumId, opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {
      albumId: albumId,
    };
    let queryParams = {
      includeSongs: opts["includeSongs"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = AlbumDto;

    return this.apiClient.callApi(
      "/api/music/albums/{albumId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getAlbums operation.
   * @callback module:api/AlbumApi~getAlbumsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/AlbumWithSongsDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/AlbumApi~getAlbumsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/AlbumWithSongsDto}
   */
  getAlbums(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      includeSongs: opts["includeSongs"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [AlbumWithSongsDto];

    return this.apiClient.callApi(
      "/api/music/albums",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the removeAlbum operation.
   * @callback module:api/AlbumApi~removeAlbumCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/AlbumApi~removeAlbumCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeAlbum(albumId, callback) {
    let postBody = null;

    let pathParams = {
      albumId: albumId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/music/albums/{albumId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updateAlbum operation.
   * @callback module:api/AlbumApi~updateAlbumCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/AlbumApi~updateAlbumCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updateAlbum(albumId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      albumId: albumId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/music/albums/{albumId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
