/**
 * Poll API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import NewPoll from "./DTOs/NewPoll";
import NewVote from "./DTOs/NewVote";
import PollJson from "./DTOs/PollJson";

/**
 * Polls service.
 * @module api/PollsApi
 * @version v1
 */
export default class PollsApi {
  /**
   * Constructs a new PollsApi.
   * @alias module:api/PollsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addPoll operation.
   * @callback module:api/PollsApi~addPollCallback
   * @param {String} error Error message, if any.
   * @param {module:model/PollJson} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PollsApi~addPollCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/PollJson}
   */
  addPoll(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = PollJson;

    return this.apiClient.callApi(
      "/api/polls",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the addVote operation.
   * @callback module:api/PollsApi~addVoteCallback
   * @param {String} error Error message, if any.
   * @param {module:model/PollJson} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PollsApi~addVoteCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/PollJson}
   */
  addVote(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = PollJson;

    return this.apiClient.callApi(
      "/api/polls/AddVote",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the deletePoll operation.
   * @callback module:api/PollsApi~deletePollCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PollsApi~deletePollCallback} callback The callback function, accepting three arguments: error, data, response
   */
  deletePoll(pollId, callback) {
    let postBody = null;

    let pathParams = {
      pollId: pollId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/polls/{pollId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPoll operation.
   * @callback module:api/PollsApi~getPollCallback
   * @param {String} error Error message, if any.
   * @param {module:model/PollJson} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PollsApi~getPollCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/PollJson}
   */
  getPoll(pollId, callback) {
    let postBody = null;

    let pathParams = {
      pollId: pollId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = PollJson;

    return this.apiClient.callApi(
      "/api/polls/{pollId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPolls operation.
   * @callback module:api/PollsApi~getPollsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/PollJson>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PollsApi~getPollsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/PollJson>}
   */
  getPolls(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [PollJson];

    return this.apiClient.callApi(
      "/api/polls",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomPoll operation.
   * @callback module:api/PollsApi~getRandomPollCallback
   * @param {String} error Error message, if any.
   * @param {module:model/PollJson} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PollsApi~getRandomPollCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/PollJson}
   */
  getRandomPoll(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = PollJson;

    return this.apiClient.callApi(
      "/api/polls/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the setReasonApproval operation.
   * @callback module:api/PollsApi~setReasonApprovalCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PollsApi~setReasonApprovalCallback} callback The callback function, accepting three arguments: error, data, response
   */
  setReasonApproval(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      reasonId: opts["reasonId"],
      approved: opts["approved"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/polls/SetReasonApprovel",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
