import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Instagram from "../images/sm-icons-instagram-glyph-logo.png";
import Twitter from "../images/twitter.png";

function HeaderNavBar() {
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="justify-content-end"
      style={{
        paddingBottom: "0px",
        borderBottom: "12px",
        borderColor: "#fff1ad",
      }}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-center" // This centers the options when they're displayed horizontally
        style={{ textAlign: "center" }} // This centers the options when they're displayed vertically
      >
        <Nav>
          <Nav.Link as={NavLink} to="/" exact>
            Home
          </Nav.Link>
          <Nav.Link as={NavLink} to="/blog">
            Tony Blog
          </Nav.Link>
          <Nav.Link as={NavLink} to="/fiction">
            Stories
          </Nav.Link>
          <Nav.Link as={NavLink} to="/podcast">
            Podcasts
          </Nav.Link>
          <NavDropdown title="The Band" id="collasible-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/band/story">
              The Story
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/band/members">
              The Band
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/band/albums">
              The Albums
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/band/live">
              The Videos
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Misc" id="nav-dropdown-misc">
            <NavDropdown.Item as={NavLink} to="/suspicioushangman">
              Suspiciously Difficult Hangman
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="http://penskefile.com">The Penske File</Nav.Link>
          
          <Nav.Link as={NavLink} to="/contact">
            Contact Me
          </Nav.Link>
          <Nav.Link style={{minWidth:"40px"}} href="https://www.instagram.com/the_pomomofo/"> <img style={{maxHeight: "20px"}} src={Instagram} alt="Instagram" /> </Nav.Link>
          <Nav.Link style={{minWidth:"40px"}} href="https://twitter.com/tony_besselink"> <img style={{maxHeight: "20px"}} src={Twitter} alt="Twitter" /> </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderNavBar;
