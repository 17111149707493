/**
 * Poll API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The UserSubmittedReasonJson model module.
* @module model/UserSubmittedReasonJson
* @version v1
*/
export default class UserSubmittedReasonJson {
    /**
    * Constructs a new <code>UserSubmittedReasonJson</code>.
    * @alias module:model/UserSubmittedReasonJson
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>UserSubmittedReasonJson</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserSubmittedReasonJson} obj Optional instance to populate.
    * @return {module:model/UserSubmittedReasonJson} The populated <code>UserSubmittedReasonJson</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserSubmittedReasonJson();
                        
            
            if (data.hasOwnProperty('userSubmittedReasonId')) {
                obj['userSubmittedReasonId'] = ApiClient.convertToType(data['userSubmittedReasonId'], 'Number');
            }
            if (data.hasOwnProperty('reasonText')) {
                obj['reasonText'] = ApiClient.convertToType(data['reasonText'], 'String');
            }
            if (data.hasOwnProperty('timeSubmitted')) {
                obj['timeSubmitted'] = ApiClient.convertToType(data['timeSubmitted'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {Number} userSubmittedReasonId
    */
    'userSubmittedReasonId' = undefined;
    /**
    * @member {String} reasonText
    */
    'reasonText' = undefined;
    /**
    * @member {Date} timeSubmitted
    */
    'timeSubmitted' = undefined;




}
