import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import PodcastShowApi from "../../api/PodcastShowApi";
import PodcastSeason from "./PodcastSeason";

function PodcastShow(props) {
  const [podcastShowSeasons, setPodcastShowSeasons] = useState([]);

  function fetchDetails() {
    if (podcastShowSeasons.length > 0) {
      return;
    }
    let apiInstance = new PodcastShowApi();
    apiInstance.getPodcastShow(props.id, (error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setPodcastShowSeasons(data.seasons);
      }
    });
  }

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={props.id}
          onClick={fetchDetails}
        >
          <h4 style={{ margin: "0" }}>{props.name}</h4>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.id}>
        <Card.Body>
          <div style={{ whiteSpace: "pre-line" }}>{props.description}</div>
          <Accordion>
            {podcastShowSeasons.length > 0 &&
              podcastShowSeasons
                .sort((a, b) => b.displayNumber - a.displayNumber)
                .map((podcastShowSeason) => {
                  return (
                    <PodcastSeason
                      key={podcastShowSeason.id}
                      id={podcastShowSeason.id}
                      showId={props.id}
                      displayName={podcastShowSeason.displayName}
                      description={podcastShowSeason.description}
                    />
                  );
                })}
          </Accordion>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default PodcastShow;
