import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import PodcastEpisodeApi from "../../api/PodcastEpisodeApi";
import PodcastEpisode from "./PodcastEpisode";

function PodcastSeason(props) {
  const [podcastEpisodes, setPodcastEpisodes] = useState([]);

  function fetchDetails() {
    if (podcastEpisodes.length > 0) {
      return;
    }
    let apiInstance = new PodcastEpisodeApi();
    apiInstance.getPodcastEpisodes(
      props.showId,
      props.id,
      null,
      (error, data, response) => {
        if (error) {
          console.error(`We had an error: ${error}`);
        } else {
          setPodcastEpisodes(data);
        }
      }
    );
  }

  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={props.id}
          onClick={fetchDetails}
        >
          <h5 style={{ margin: "0" }}>{props.displayName}</h5>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.id}>
        <Card.Body>
          <p style={{ whiteSpace: "pre-line" }}>{props.description}</p>
          <Accordion>
            {podcastEpisodes
            .sort((a, b) => b.numberInSeason - a.numberInSeason)
            .map((podcastEpisode) => {
              return (
                <PodcastEpisode
                  key={podcastEpisode.id}
                  name={podcastEpisode.name}
                  id={podcastEpisode.id}
                  publishedDate={podcastEpisode.publishedDate}
                  description={podcastEpisode.description}
                  link={
                    podcastEpisode.webPageLink || podcastEpisode.downloadLink
                  }
                />
              );
            })}
          </Accordion>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default PodcastSeason;
