import React from "react";
import ResponsiveYoutube from "../../common/ResponsiveYoutube";

function Video(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <h5>{props.title}</h5>
      {props.description?.length > 0 && <p>{props.description}</p>}
      <ResponsiveYoutube
        title={props.title}
        youtubeVideoId={props.youtubeVideoId}
      />
    </div>
  );
}

export default Video;
