/**
 * Tony's Music
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The SongDto model module.
* @module model/SongDto
* @version v1
*/
export default class SongDto {
    /**
    * Constructs a new <code>SongDto</code>.
    * @alias module:model/SongDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SongDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SongDto} obj Optional instance to populate.
    * @return {module:model/SongDto} The populated <code>SongDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SongDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('credits')) {
                obj['credits'] = ApiClient.convertToType(data['credits'], 'String');
            }
            if (data.hasOwnProperty('lyrics')) {
                obj['lyrics'] = ApiClient.convertToType(data['lyrics'], 'String');
            }
            if (data.hasOwnProperty('link')) {
                obj['link'] = ApiClient.convertToType(data['link'], 'String');
            }
            if (data.hasOwnProperty('trackNumber')) {
                obj['trackNumber'] = ApiClient.convertToType(data['trackNumber'], 'Number');
            }
            if (data.hasOwnProperty('albumReleaseDate')) {
                obj['albumReleaseDate'] = ApiClient.convertToType(data['albumReleaseDate'], 'Date');
            }
            if (data.hasOwnProperty('albumName')) {
                obj['albumName'] = ApiClient.convertToType(data['albumName'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {String} credits
    */
    'credits' = undefined;
    /**
    * @member {String} lyrics
    */
    'lyrics' = undefined;
    /**
    * @member {String} link
    */
    'link' = undefined;
    /**
    * @member {Number} trackNumber
    */
    'trackNumber' = undefined;
    /**
    * @member {Date} albumReleaseDate
    */
    'albumReleaseDate' = undefined;
    /**
    * @member {String} albumName
    */
    'albumName' = undefined;




}
