/*
 * SocialMedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.27
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import {SocialMediaType} from './SocialMediaType';

/**
 * The SocialMediaDto model module.
 * @module model/SocialMediaDto
 * @version v1
 */
export class SocialMediaDto {
  /**
   * Constructs a new <code>SocialMediaDto</code>.
   * @alias module:model/SocialMediaDto
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>SocialMediaDto</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SocialMediaDto} obj Optional instance to populate.
   * @return {module:model/SocialMediaDto} The populated <code>SocialMediaDto</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new SocialMediaDto();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('url'))
        obj.url = ApiClient.convertToType(data['url'], 'String');
      if (data.hasOwnProperty('postIdentifier'))
        obj.postIdentifier = ApiClient.convertToType(data['postIdentifier'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = SocialMediaType.constructFromObject(data['type']);
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
SocialMediaDto.prototype.id = undefined;

/**
 * @member {String} url
 */
SocialMediaDto.prototype.url = undefined;

/**
 * @member {String} postIdentifier
 */
SocialMediaDto.prototype.postIdentifier = undefined;

/**
 * @member {module:model/SocialMediaType} type
 */
SocialMediaDto.prototype.type = undefined;

