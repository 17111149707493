import React, { useState, useRef } from "react";
import TomImage from "../images/tom.jpg";
import { Button, Alert } from "react-bootstrap";

function Questionaire() {
  const [alertText, setAlertText] = useState("");
  const noCounter = useRef(0);
  const submitted = useRef(false);

  const messages = {
    1: "Really?",
    2: "That doesn't fit the data we have of you.",
    3: "We mined a lot of data. Something's not making sense.",
    4: "Oh, I think I've got it! A re-scan of your data shows you're ashamed of many aspects of your life. Is this one of those times?",
    5: "It's ok, I'm not here to judge. I'm just here to mine data.",
    6: "Seriously, it's fine. A pervert's data is just as valuable as a non-pervert's data. Some would say more valuable.",
    7: "With all the data I have, I think it's safe to say I know you better than you know yourself, Peter.",
    8: "Why must you keep lying to yourself?",
    9: "You've clicked this button 9 times now. Who are you trying to convince, me or you?",
    10: "I tell you what, I'm just going to disable that button. Now, finally, we might get some honesty around here.",
  };

  function onNoClick() {
    noCounter.current = noCounter.current + 1;
    setAlertText(messages[noCounter.current]);
  }

  function onSubmitted() {
    submitted.current = true;
    setAlertText("Thank you for your honesty.");
  }

  // TODO: The "name" part in input means you can't have multiple of these components on the page at once.
  // I could add a guid to the name to solve this, but surely there's a better way.

  return (
    <div style={{ textAlign: "center" }}>
      <h5>Please help!</h5>
      <br />
      We're training a new AI algorithm, and from all the data we've mined from
      you, it says you find this photo arousing. Is this true?
      <br />
      <img
        src={TomImage}
        style={{ width: "100%", marginBottom: "10px" }}
        alt="A man sweeping a flaming pentagram."
      />
      <div>
        <span style={{ margin: "7.5px" }}>
          <input
            type="radio"
            name="questionaire"
            value="yes"
            checked
            readOnly
            disabled={submitted.current}
          />
          Yes
        </span>
        <span style={{ margin: "7.5px" }}>
          <input
            type="radio"
            name="questionaire"
            value="no"
            onClick={onNoClick}
            disabled={
              noCounter.current >= 10 || submitted.current ? true : false
            }
          />
          No
        </span>
      </div>
      {alertText.length > 0 ? (
        <Alert variant="primary">{alertText}</Alert>
      ) : (
        <div style={{ margin: "15px" }} />
      )}
      <Button
        variant="secondary"
        onClick={onSubmitted}
        hidden={submitted.current}
      >
        Submit
      </Button>
    </div>
  );
}

export default Questionaire;
