import React, { useState } from "react";
import SuspiciousHangmanWordDisplay from "./SuspiciousHangmanWordDisplay";
import SuspiciousHangmanIncorrectCounter from "./SuspiciousHangmanIncorrectCounter";
import {Button, Alert} from "react-bootstrap";
import SuspiciousHangmanApi from "../../api/SuspiciousHangmanApi";


function SuspiciousHangman() {
    const [incorrectLetters, setIncorrectLetters] = useState([]);
    const [correctLetters, setCorrectLetters] = useState({});
    const [wordLength, setWordLength] = useState(getRandomWordLength());
    const [nextGuessLetter, setNextGuessLetter] = useState("");
    const [winningWord, setWinningWord] = useState("");
    const [alertBoxContents, setAlertBoxContents] = useState({text: "", variant: ""});

    const winningMessages = ["You got lucky.", "That was close.", "It's a victory, but not a great one", "Took you a while."];

    function setAlertBox(text, variant) {
      setAlertBoxContents({text: text, variant: variant});
    }

    function getRandomWordLength() {
        return getRandomInt(3, 9);
    };

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function onNextGuessLetterChange(event){        
        let nextGuess = event.target.value;
        if (nextGuess.length > 1) {
            nextGuess = nextGuess.charAt(nextGuess.length - 1);
        }

        setNextGuessLetter(nextGuess);
    }

    function submitGuess() {
        let lowercaseGuessLetter = nextGuessLetter.toLowerCase();

        if (lowercaseGuessLetter.length !== 1 || !/^[a-zA-Z]$/.test(lowercaseGuessLetter)){
          setAlertBox("You need to enter a letter", "danger");
          return;
        }

        if (letterHasAlreadyBeenGuessed(lowercaseGuessLetter)){
          setAlertBox("You've already guessed that letter", "danger");
          return;
        }
        
        let apiInstance = new SuspiciousHangmanApi();
        
        let requestBody = {
          wordLength: wordLength,
          wrongGuesses: incorrectLetters,
          correctGuesses: correctLetters,
          guessedLetter: lowercaseGuessLetter
        };

        apiInstance.submitGuess(
          {
            body: requestBody
          },
          (error, data, response) => {
            if (error) {
              setAlertBox("Error submitting: " + error, "danger");
            } else {
              handleResponse(data);
            }
          }
        );
    }

    function letterHasAlreadyBeenGuessed(letter) {
      if (incorrectLetters.includes(letter)){
        return true;
      }

      if (correctLetters[letter]){
        return true;
      }

      return false;
    }

    function handleResponse(hangmanResponse){
      setAlertBox("", ""); // Clear any alert box message when we get the response

      if (hangmanResponse.gameOverWord !== null){
        setWinningWord(hangmanResponse.gameOverWord);
      }

      if (hangmanResponse.correctGuess === false && !incorrectLetters.includes(hangmanResponse.guessedLetter)) {
        setIncorrectLetters([...incorrectLetters, hangmanResponse.guessedLetter]);
      }
      else {
        let oldCorrectLetters = {...correctLetters};
        oldCorrectLetters[hangmanResponse.guessedLetter] = hangmanResponse.correctGuessPositions;
        setCorrectLetters(oldCorrectLetters);

        if (hangmanResponse.gameOverWord !== null) {
          // Set the congratulatory message
          setAlertBox(winningMessages[getRandomInt(1,4)], "success");
        }

      }
    }

    function resetGame(){
      setIncorrectLetters([]);
      setCorrectLetters({});
      setWordLength(getRandomWordLength());
      setNextGuessLetter("");
      setWinningWord("");
      setAlertBox("", "");
    }

    function handleKeyPress(event) {
      if(event.key === 'Enter' && winningWord === ""){
        submitGuess();
      }
    }

    function onSubmitGuess(event) {
      event.preventDefault();
      submitGuess();
    }

    return (
        <>
            <h3 style={{textAlign: "center"}}>Suspiciously Difficult Hangman</h3>

            {alertBoxContents.text !== "" && <Alert variant={alertBoxContents.variant} onClose={() => setAlertBox("", "")} dismissible>{alertBoxContents.text}</Alert>}

            <SuspiciousHangmanWordDisplay wordLength={wordLength} correctLetters={correctLetters} winningWord={winningWord} />
            <SuspiciousHangmanIncorrectCounter incorrectGuessCount={incorrectLetters.length} />

            <br/>
            <div style={{textAlign: "center"}}>
                <label>Enter Letter:</label>
                <input 
                    type="text" 
                    value={nextGuessLetter} 
                    onChange={onNextGuessLetterChange}
                    onKeyPress={handleKeyPress} 
                    style={{width:"50px", resize:"none", margin: "5px"}}>    
                </input>
                {winningWord === "" ? <Button onClick={onSubmitGuess}>Submit</Button> : <Button onClick={resetGame}>Reset</Button>}
            </div>
        </>
    );
}


export default SuspiciousHangman;