import React, { useEffect, useState } from "react";

function ResponsiveYoutube(props) {
  const [heightByWidthPercentage, setHeightByWidthPercentage] = useState(
    "56.25%"
  );

  useEffect(() => {
    if (!props.youtubeVideoId){
      return;
    }

    fetch(
      `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${props.youtubeVideoId}&format=json`
    ).then((response) => {
      if (response.status !== 200) {
        // Something went wrong getting the video dimensions. We'll just use the 16:9, it's not the end of the world.
        return;
      }
      response.json().then((data) => {
        let percentage = `${(data.height / data.width) * 100}%`;
        setHeightByWidthPercentage(percentage);
      });
    });
  });

  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: heightByWidthPercentage,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        title={props.title}
        src={`https://www.youtube.com/embed/${props.youtubeVideoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default ResponsiveYoutube;
