import React from "react";
import HeaderNavBar from "./HeaderNavBar";

function Header() {
  const headerBackgroundStyle = {
    background: "#89C0EB",
    color: "#FFFFFF",
    borderBottom: "12px solid #FFF1AD",
    marginBottom: "20px",
  };

  const headerTextStyle = {
    width: "770px",
    margin: "0 auto",
    textShadow: "2px 2px 8px #000000",
  };

  return (
    <div style={headerBackgroundStyle} className="box-shadow--8dp">
      <h1
        style={{
          ...headerTextStyle,
          font: "bold 3.0em Arial, Arial, Sans-Serif",
          padding: "30px 30px 5px",
        }}
      >
        Tony Besselink
      </h1>
      <h3
        style={{
          ...headerTextStyle,
          font: "bold 1.1em Arial, Arial, Sans-Serif",
          padding: "5px 20px 20px 37px",
        }}
      >
        Now with fewer viruses
      </h3>
      <HeaderNavBar />
    </div>
  );
}

export default Header;
