import React, { useState, useEffect } from "react";
import Album from "./Album";
import AlbumApi from "../../api/AlbumApi";

function AlbumPage() {
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    let apiInstance = new AlbumApi();
    let opts = {
      includeSongs: true,
    };
    apiInstance.getAlbums(opts, (error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setAlbums(data);
      }
    });
  }, []);

  return (
    <>
      <h1>Discography</h1>
      {albums
        .sort((a, b) => b.displayNumber - a.displayNumber)
        .map((album) => {
          return (
            <Album
              key={album.id}
              albumCoverUrl={album.albumCoverUrl}
              name={album.name}
              releaseDate={album.releaseDate}
              songs={album.songs}
            />
          );
        })}
    </>
  );
}

export default AlbumPage;
