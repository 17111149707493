/**
 * Podcast API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import ShowDetailedDto from "./DTOs/ShowDetailedDto";
import ShowDto from "./DTOs/ShowDto";
import ShowForCreationDto from "./DTOs/ShowForCreationDto";

/**
 * PodcastShow service.
 * @module api/PodcastShowApi
 * @version v1
 */
export default class PodcastShowApi {
  /**
   * Constructs a new PodcastShowApi.
   * @alias module:api/PodcastShowApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addPodcastShow operation.
   * @callback module:api/PodcastShowApi~addPodcastShowCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ShowDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastShowApi~addPodcastShowCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ShowDto}
   */
  addPodcastShow(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = ShowDto;

    return this.apiClient.callApi(
      "/api/podcast/shows",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPodcastShow operation.
   * @callback module:api/PodcastShowApi~getPodcastShowCallback
   * @param {String} error Error message, if any.
   * @param {module:model/ShowDetailedDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PodcastShowApi~getPodcastShowCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/ShowDetailedDto}
   */
  getPodcastShow(showId, callback) {
    let postBody = null;

    let pathParams = {
      showId: showId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = ShowDetailedDto;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getPodcastShows operation.
   * @callback module:api/PodcastShowApi~getPodcastShowsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/ShowDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastShowApi~getPodcastShowsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/ShowDto>}
   */
  getPodcastShows(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      PageNumber: opts["pageNumber"],
      PageSize: opts["pageSize"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [ShowDto];

    return this.apiClient.callApi(
      "/api/podcast/shows",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the removePodcastShow operation.
   * @callback module:api/PodcastShowApi~removePodcastShowCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/PodcastShowApi~removePodcastShowCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removePodcastShow(showId, callback) {
    let postBody = null;

    let pathParams = {
      showId: showId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updatePodcastShow operation.
   * @callback module:api/PodcastShowApi~updatePodcastShowCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/PodcastShowApi~updatePodcastShowCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updatePodcastShow(showId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      showId: showId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/podcast/shows/{showId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
