import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import ResponsiveYoutube from "../common/ResponsiveYoutube";

function LiveSong(props) {
  const [selectedYoutubeId, setSelectedYoutubeId] = useState("");

  props.livePerformances.sort((a, b) => b.displayNumber - a.displayNumber);

  useEffect(() => {
    setSelectedYoutubeId(props.livePerformances[0].youtubeId);
  }, [props.livePerformances]);

  return (
    <>
      <h2 style={{ textAlign: "center" }}>{props.songName}</h2>
      <Row>
        <Col
          md={{ span: 6, order: "first" }}
          sm={{ span: 12, order: "last" }}
          style={{ margin: "auto" }}
        >
          <Accordion defaultActiveKey={props.livePerformances[0].id}>
            {props.livePerformances.map((livePerformance) => {
              return (
                <Card key={livePerformance.id}>
                  <Card.Header style={{ padding: "0px" }}>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={livePerformance.id}
                      style={{
                        padding: "0 0 0 3px",
                        margin: "6px",
                      }}
                      onClick={() =>
                        setSelectedYoutubeId(livePerformance.youtubeId)
                      }
                    >
                      <h5
                        style={{
                          margin: "0px",
                          color:
                            selectedYoutubeId === livePerformance.youtubeId &&
                            "rgb(0, 0, 238)",
                          textDecoration:
                            selectedYoutubeId === livePerformance.youtubeId &&
                            "underline",
                        }}
                      >
                        {livePerformance.title}
                      </h5>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={livePerformance.id}>
                    <Card.Body style={{ padding: "10px" }}>
                      <p style={{ color: "#777", margin: "0" }}>
                        {livePerformance.performanceDate.toDateString()}
                      </p>{" "}
                      {livePerformance.description}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </Col>
        <Col md={6} sm={12}>
          <ResponsiveYoutube
            youtubeVideoId={selectedYoutubeId}
          ></ResponsiveYoutube>
        </Col>
      </Row>
      <hr style={{ marginTop: "50px" }} />
    </>
  );
}

export default LiveSong;
