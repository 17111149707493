import React, { useState, useEffect } from "react";
import BlogsApi from "../../api/BlogsApi";
import Blog from "./Blog";
import { Button } from "react-bootstrap";
import BlogMenu from "./BlogMenu";

function BlogPage(props) {
  const [blogs, setBlogs] = useState([]);
  const [paginationLinks, setPaginationLinks] = useState({
    previousLink: null,
    nextLink: null,
  });
  const query = new URLSearchParams(props.location.search);
  const pageNumber = query.get("page") ?? 1;
  const monthFilter = query.get("month");


  function OnNextPageClick() {
    MovePage(1);
  }

  function OnPreviousPageClick() {
    MovePage(-1);
  }

  function MovePage(pageDelta) {
    props.history.push({
      pathname: "/blog",
      search:
        "?" + new URLSearchParams({ page: parseInt(pageNumber) + pageDelta }),
    });
  }

  const setSideBox = props.setPredeterminedSidePanels;

  useEffect(() => {
    function getMultipleBlogs(apiInstance) {
      let opts = {
        publishedMonth: null,
        pageNumber: pageNumber,
      };
      if (monthFilter) {
        opts = {
          publishedMonth: monthFilter,
          page: 1,
          pageSize: 100,
        };
      }
      apiInstance.getBlogs(opts, (error, data, response) => {
        if (error) {
          console.error(`We had an error: ${error}`);
        } else {
          let xPagination = JSON.parse(response.headers["x-pagination"]);
          if (xPagination){
            setPaginationLinks({
              previousLink: xPagination.PreviousPageLink,
              nextLink: xPagination.NextPageLink,
            });
          }
          setBlogs(data);
        }
      });
    }

    function getSpecificBlog(apiInstance, blogId) {
      apiInstance.getBlog(blogId, (error, data, response) => {
        if (error) {
          console.error(`We had an error: ${error}`);
        } else {
          setPaginationLinks({
            previousLink: null,
            nextLink: null,
          });
          setBlogs([data]);
        }
      });
    }

    setSideBox([{path: "/blog/:blogId?", component: <BlogMenu />}]);

    let apiInstance = new BlogsApi();
    let blogIdInt = parseInt(props.match.params.blogId);

    if (blogIdInt) {
      getSpecificBlog(apiInstance, blogIdInt);
    } else {
      getMultipleBlogs(apiInstance);
    }
  }, [pageNumber, monthFilter, props.match.params.blogId, setSideBox]);

  return (
    <>
      {blogs.map((blog) => {
        return (
          <React.Fragment key={blog.id}>
            <Blog
              id={blog.id}
              title={blog.title}
              publishedDate={blog.publishedDate}
              content={blog.content}
            />
            <hr />
          </React.Fragment>
        );
      })}
      <div id="PaginationControls" style={{ textAlign: "center" }}>
        <span>
          {paginationLinks.previousLink && (
            <Button variant="link" onClick={OnPreviousPageClick}>
              Newer Blogs
            </Button>
          )}
          {paginationLinks.previousLink && paginationLinks.nextLink && (
            <span>|</span>
          )}
          {paginationLinks.nextLink && (
            <Button variant="link" onClick={OnNextPageClick}>
              Older Blogs
            </Button>
          )}
        </span>
      </div>
    </>
  );
}

export default BlogPage;

/* 
So how should the URL behave?
/blog -> The first three blogs, returned by the API.
/blog/{blogId} -> A specific blog denoted by the id.
/blog?page=x -> A specific page
/blog?month=2020-03 -> A specific month. 

Ok, the links are showing up correctly. 



*/

/*
I need to figure out how to access this info from the return.
x-pagination: {"TotalCount":7,"PageSize":3,"CurrentPage":1,"TotalPages":3,"PreviousPageLink":null,"NextPageLink":"https://localhost:44366/api/blogs?IncludeUnpublished=False\u0026PageNumber=2\u0026PageSize=3"}

I can't see it in the response object we get. 

Does x-pagination show up?
Nope.

We can add the header to Swagger like so 
https://stackoverflow.com/questions/47556015/net-core-2-0-web-api-how-to-add-a-custom-header-parameter-in-swagger

Might be worth exploring that way first. It seems more correct to let Swagger know the header exists.

*/

/*
content: "I never need to do this again."
​​
id: 2
​​
published: true
​​
publishedDate: Date Sat Jan 16 2021 06:29:21 GMT+1100 (Australian Eastern Daylight Time)
​​
title: "This is a published blog for real. "
​​
url: "https://localhost:44366/api/blogs/2"

*/

/* Not having a lot of luck getting this damn x-pagination to show up in the api. Might have to go through the API
    and see where I can find it stripping it out.

*/

/*
Ohh, here's a tracked issue for what I'm experiencing https://github.com/visionmedia/superagent/issues/713
It points to this as being the issue/solution 
*/
