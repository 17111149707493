/*
 * SuspiciousHangman
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "./ApiClient";
import {HangmanGuess} from './DTOs/HangmanGuess';
import {HangmanResponse} from './DTOs/HangmanResponse';

/**
* SuspiciousHangman service.
* @module api/SuspiciousHangmanApi
* @version v1
*/
export default class SuspiciousHangmanApi {

    /**
    * Constructs a new SuspiciousHangmanApi. 
    * @alias module:api/SuspiciousHangmanApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the submitGuess operation.
     * @callback moduleapi/SuspiciousHangmanApi~submitGuessCallback
     * @param {String} error Error message, if any.
     * @param {module:model/HangmanResponse{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/HangmanGuess} opts.body 
     * @param {module:api/SuspiciousHangmanApi~submitGuessCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    submitGuess(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = HangmanResponse;

      return this.apiClient.callApi(
        '/api/SuspiciousHangman', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}