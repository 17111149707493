/**
 * Blog API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import BlogMenuDto from "./DTOs/BlogMenuDto";

/**
 * BlogsMenu service.
 * @module api/BlogsMenuApi
 * @version v1
 */
export default class BlogsMenuApi {
  /**
   * Constructs a new BlogsMenuApi.
   * @alias module:api/BlogsMenuApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the getBlogMenu operation.
   * @callback module:api/BlogsMenuApi~getBlogMenuCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/BlogMenuDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/BlogsMenuApi~getBlogMenuCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/BlogMenuDto>}
   */
  getBlogMenu(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [BlogMenuDto];

    return this.apiClient.callApi(
      "/api/blogsmenu",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
