import React, { useState, useEffect } from "react";
import EmbeddedInstagram from "./EmbeddedInstagram";
import EmbeddedTwitter from "./EmbeddedTwitter";
import SocialMediaApi from "../../api/SocialMediaApi";

function RandomSocialMedia() {
  const [randomSocialMedia, setRandomSocialMedia] = useState();

  useEffect(() => {
    let apiInstance = new SocialMediaApi();
    apiInstance.getRandomSocialMedia((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setRandomSocialMedia(data);
      }
    });
  }, []);

  return (
    <>
    {
        (randomSocialMedia &&
        
        
        (randomSocialMedia?.type === "Instagram"
        ? <EmbeddedInstagram PostIdentifier={randomSocialMedia?.postIdentifier}/>
        : <EmbeddedTwitter PostIdentifier={randomSocialMedia?.postIdentifier} />
        ) 
        )
    }
    </>
  );
}

export default RandomSocialMedia;
