import React, { useState, useRef, useEffect } from "react";

function TimeShiftedClock() {
  const timeShiftedMinutes = useRef(
    Math.floor(Math.random() * (50 - -50 + 1)) + -50
  );

  const [shiftedTime, setShiftedTime] = useState("");

  useEffect(() => {
    const interval = setInterval(function () {
      var date = new Date();
      date.setMinutes(date.getMinutes() + timeShiftedMinutes.current);
      setShiftedTime(date.toLocaleTimeString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function getTitle() {
    if (timeShiftedMinutes.current < 0) {
      return `The Time ${Math.abs(timeShiftedMinutes.current)} Minutes Ago Was`;
    }
    return `In ${timeShiftedMinutes.current} Minutes The Time Will Be`;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h5>{getTitle()}</h5>
      <br />
      <h4>
        <b>{shiftedTime}</b>
      </h4>
    </div>
  );
}

export default TimeShiftedClock;
