/**
 * Fiction
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The FictionDto model module.
* @module model/FictionDto
* @version v1
*/
export default class FictionDto {
    /**
    * Constructs a new <code>FictionDto</code>.
    * @alias module:model/FictionDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>FictionDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/FictionDto} obj Optional instance to populate.
    * @return {module:model/FictionDto} The populated <code>FictionDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FictionDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('createdDate')) {
                obj['createdDate'] = ApiClient.convertToType(data['createdDate'], 'Date');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('displayOrder')) {
                obj['displayOrder'] = ApiClient.convertToType(data['displayOrder'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} title
    */
    'title' = undefined;
    /**
    * @member {Date} createdDate
    */
    'createdDate' = undefined;
    /**
    * @member {String} content
    */
    'content' = undefined;
    /**
    * @member {Number} displayOrder
    */
    'displayOrder' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;




}
