import React, { useState, useEffect } from "react";
import FictionApi from "../../api/FictionApi";
import Fiction from "./Fiction";
import FictionMenu from "./FictionMenu";

function FictionPage(props) {
  let [fictionPieces, setFictionPieces] = useState([]);
  let [specificFiction, setSpecificFiction] = useState();

  let fictionId = parseInt(props.match.params.fictionId);

  useEffect(() => {
    if (fictionPieces.length === 0) {
      let apiInstance = new FictionApi();
      apiInstance.getFictions(null, (error, data, response) => {
        setFictionPieces(data);
      });
    }
    if (fictionId) {
      let found = fictionPieces.find((fiction) => fiction.id === fictionId);
      setSpecificFiction(found);
    } else {
      setSpecificFiction();
    }
  }, [specificFiction, fictionPieces, fictionId]);

  return (
    <>
      {specificFiction ? (
        <Fiction
          title={specificFiction.title}
          createdDate={specificFiction.createdDate}
          content={specificFiction.content}
        />
      ) : (
        <div>
          <h3>Here are some stories what I have written: </h3>
          <FictionMenu fictions={fictionPieces} />
        </div>
        
      )}
    </>
  );
}
export default FictionPage;
