/*
 * SocialMedia
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.27
 *
 * Do not edit the class manually.
 *
 */
import ApiClient from "./ApiClient";
import {Operation} from './DTOs/Operation';
import {SocialMediaDto} from './DTOs/SocialMediaDto';
import {SocialMediaType} from './DTOs/SocialMediaType';

/**
* SocialMedia service.
* @module api/SocialMediaApi
* @version v1
*/
export default class SocialMediaApi {

    /**
    * Constructs a new SocialMediaApi. 
    * @alias module:api/SocialMediaApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addSocialMedia operation.
     * @callback moduleapi/SocialMediaApi~addSocialMediaCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocialMediaDto{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.approved 
     * @param {String} opts.postIdentifier 
     * @param {module:model/SocialMediaType} opts.type 
     * @param {module:api/SocialMediaApi~addSocialMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addSocialMedia(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        'Approved': opts['approved'],'PostIdentifier': opts['postIdentifier'],'Type': opts['type']
      };

      let authNames = ['Bearer'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SocialMediaDto;

      return this.apiClient.callApi(
        '/api/SocialMedia', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteSocialMedia operation.
     * @callback moduleapi/SocialMediaApi~deleteSocialMediaCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} socialMediaId 
     * @param {module:api/SocialMediaApi~deleteSocialMediaCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteSocialMedia(socialMediaId, callback) {
      
      let postBody = null;
      // verify the required parameter 'socialMediaId' is set
      if (socialMediaId === undefined || socialMediaId === null) {
        throw new Error("Missing the required parameter 'socialMediaId' when calling deleteSocialMedia");
      }

      let pathParams = {
        'SocialMediaId': socialMediaId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/SocialMedia/{SocialMediaId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRandomSocialMedia operation.
     * @callback moduleapi/SocialMediaApi~getRandomSocialMediaCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocialMediaDto{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/SocialMediaApi~getRandomSocialMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getRandomSocialMedia(callback) {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SocialMediaDto;

      return this.apiClient.callApi(
        '/api/SocialMedia/random', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSocialMedia operation.
     * @callback moduleapi/SocialMediaApi~getSocialMediaCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocialMediaDto{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} socialMediaId 
     * @param {module:api/SocialMediaApi~getSocialMediaCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getSocialMedia(socialMediaId, callback) {
      
      let postBody = null;
      // verify the required parameter 'socialMediaId' is set
      if (socialMediaId === undefined || socialMediaId === null) {
        throw new Error("Missing the required parameter 'socialMediaId' when calling getSocialMedia");
      }

      let pathParams = {
        'SocialMediaId': socialMediaId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = SocialMediaDto;

      return this.apiClient.callApi(
        '/api/SocialMedia/{SocialMediaId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getSocialMedias operation.
     * @callback moduleapi/SocialMediaApi~getSocialMediasCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SocialMediaDto>{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.onlyApproved 
     * @param {Number} opts.pageNumber 
     * @param {Number} opts.pageSize 
     * @param {module:api/SocialMediaApi~getSocialMediasCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getSocialMedias(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'OnlyApproved': opts['onlyApproved'],'PageNumber': opts['pageNumber'],'PageSize': opts['pageSize']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = [SocialMediaDto];

      return this.apiClient.callApi(
        '/api/SocialMedia', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateSocialMedia operation.
     * @callback moduleapi/SocialMediaApi~updateSocialMediaCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} socialMediaId 
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/Operation>} opts.body 
     * @param {module:api/SocialMediaApi~updateSocialMediaCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateSocialMedia(socialMediaId, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'socialMediaId' is set
      if (socialMediaId === undefined || socialMediaId === null) {
        throw new Error("Missing the required parameter 'socialMediaId' when calling updateSocialMedia");
      }

      let pathParams = {
        'SocialMediaId': socialMediaId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/SocialMedia/{SocialMediaId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}