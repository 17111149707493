import React from "react";
import RandomPoll from "./Poll/RandomPoll";
import RandomVideo from "./Video/RandomVideo";
import Feedback from "./Feedback";
import FeedbackRequester from "./FeedbackRequester";
import Questionaire from "./Questionaire";
import TimeShiftedClock from "./TimeShiftedClock";
import RandomSocialMedia from "../SocialMediaViewer/RandomSocialMedia";
import SuspiciousHangman from "../SuspiciousHangman/SuspiciousHangman";
import { useLocation } from "react-router-dom";


function RandomSideComponent() {

  const sideComponents = [<RandomPoll />, <RandomVideo />, <Feedback />, <FeedbackRequester />, <Questionaire />, <TimeShiftedClock />, <RandomSocialMedia />, <SuspiciousHangman />];

  // TODO: This allows the same side component to be displayed. Not ideal, but not a deal breaker.

  useLocation();

  return (
    <>
        {sideComponents[Math.floor(Math.random() * sideComponents.length)]}
    </>
  );
}

export default RandomSideComponent;
