import React, { useState, useEffect } from "react";
import Video from "./Video";
import VideoApi from "../../../api/VideoApi";

function RandomVideo() {
  const [randomVideo, setRandomVideo] = useState();

  useEffect(() => {
    let apiInstance = new VideoApi();
    apiInstance.getRandomVideo((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setRandomVideo(data);
      }
    });
  }, []);

  return (
    <Video
      title={randomVideo?.title}
      description={randomVideo?.description}
      youtubeVideoId={randomVideo?.youtubeVideoId}
    />
  );
}

export default RandomVideo;
