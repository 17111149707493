import React, { useState, useEffect } from "react";
import Poll from "./Poll.js";
import PollApi from "../../../api/PollsApi";

function RandomPoll() {
  const [randomPoll, setRandomPoll] = useState();

  useEffect(() => {
    let apiInstance = new PollApi();
    apiInstance.getRandomPoll((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        setRandomPoll(data);
      }
    });
  }, []);

  return (
    <Poll
      questionId={randomPoll?.questionId}
      questionText={randomPoll?.questionText}
      pollOptions={randomPoll?.pollOptions}
    />
  );
}

export default RandomPoll;
