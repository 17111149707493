/**
 * Feedback
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import FeedbackDto from "./DTOs/FeedbackDto";
import FeedbackForCreationDto from "./DTOs/FeedbackForCreationDto";

/**
 * Feedback service.
 * @module api/FeedbackApi
 * @version v1
 */
export default class FeedbackApi {
  /**
   * Constructs a new FeedbackApi.
   * @alias module:api/FeedbackApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the addFeedback operation.
   * @callback module:api/FeedbackApi~addFeedbackCallback
   * @param {String} error Error message, if any.
   * @param {module:model/FeedbackDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/FeedbackApi~addFeedbackCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FeedbackDto}
   */
  addFeedback(opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = FeedbackDto;

    return this.apiClient.callApi(
      "/api/Feedback",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the deleteFeedback operation.
   * @callback module:api/FeedbackApi~deleteFeedbackCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/FeedbackApi~deleteFeedbackCallback} callback The callback function, accepting three arguments: error, data, response
   */
  deleteFeedback(feedbackId, callback) {
    let postBody = null;

    let pathParams = {
      FeedbackId: feedbackId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/Feedback/{FeedbackId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getFeedback operation.
   * @callback module:api/FeedbackApi~getFeedbackCallback
   * @param {String} error Error message, if any.
   * @param {module:model/FeedbackDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/FeedbackApi~getFeedbackCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FeedbackDto}
   */
  getFeedback(feedbackId, callback) {
    let postBody = null;

    let pathParams = {
      FeedbackId: feedbackId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = FeedbackDto;

    return this.apiClient.callApi(
      "/api/Feedback/{FeedbackId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getFeedbacks operation.
   * @callback module:api/FeedbackApi~getFeedbacksCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/FeedbackDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/FeedbackApi~getFeedbacksCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/FeedbackDto>}
   */
  getFeedbacks(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      OnlyApproved: opts["onlyApproved"],
      PageNumber: opts["pageNumber"],
      PageSize: opts["pageSize"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [FeedbackDto];

    return this.apiClient.callApi(
      "/api/Feedback",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomFeedback operation.
   * @callback module:api/FeedbackApi~getRandomFeedbackCallback
   * @param {String} error Error message, if any.
   * @param {module:model/FeedbackDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/FeedbackApi~getRandomFeedbackCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/FeedbackDto}
   */
  getRandomFeedback(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = FeedbackDto;

    return this.apiClient.callApi(
      "/api/Feedback/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updateFeedback operation.
   * @callback module:api/FeedbackApi~updateFeedbackCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/FeedbackApi~updateFeedbackCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updateFeedback(feedbackId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      FeedbackId: feedbackId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/Feedback/{FeedbackId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
