import React, { useState, useEffect } from "react";
import LiveVideoApi from "../../api/LiveVideoApi";
import LiveSong from "./LiveSong";

function MusicVideoPage() {
  const [songVideos, setSongVideos] = useState([]);

  useEffect(() => {
    let apiInstance = new LiveVideoApi();
    apiInstance.getLiveVideos((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        let liveSongs = unflattenSongs(data);
        setSongVideos(liveSongs);
      }
    });
  }, []);

  function unflattenSongs(songArray) {
    let working = {};
    let output = [];

    songArray.forEach((liveVideo) => {
      if (!(liveVideo.songId in working)) {
        working[liveVideo.songId] = {
          songId: liveVideo.songId,
          songName: liveVideo.songName,
          livePerformances: [],
        };
      }

      working[liveVideo.songId].livePerformances.push({
        id: liveVideo.id,
        title: liveVideo.videoTitle,
        youtubeId: liveVideo.youTubeLink,
        description: liveVideo.videoDescription,
        performanceDate: liveVideo.performanceDate,
        displayNumber: liveVideo.displayOrderNumber,
      });
    });

    for (let i in working) {
      output.push(working[i]);
    }

    return output;
  }

  function getMaxPerformancesDisplayValue(songVideo) {
    let performanceDisplayValues = songVideo.livePerformances.map(
      (liveVideo) => {
        return liveVideo.displayNumber || 0;
      }
    );

    return Math.max(...performanceDisplayValues);
  }

  return (
    <>
      {songVideos
        .sort(
          (a, b) =>
            getMaxPerformancesDisplayValue(b) -
            getMaxPerformancesDisplayValue(a)
        )
        .map((liveVideo) => {
          return (
            <LiveSong
              key={liveVideo.songId}
              songName={liveVideo.songName}
              livePerformances={liveVideo.livePerformances}
            />
          );
        })}
    </>
  );
}

export default MusicVideoPage;
