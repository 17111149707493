import React from "react";
import BandGroupImage from "../images/bandgroup.jpg";
import ResponsiveYoutube from "../common/ResponsiveYoutube";

function BandStory() {
  return (
    <div>
      <h1 className="side_panel_box_centered">In The Beginning...</h1>
      <br />

      <br />
      <p>
        Bands are created for many reasons. A passion for making music. An
        attempt to recapture youth after a mid-life crisis. Actually, they're
        the only two reasons.
      </p>

      <p>
        Or so the world thought! Until Tony Besselink invented a third reason to
        put a band together: To waste people's time. But what drives a man to
        such a thing. Well it all started with this...
      </p>
      <br />
      <div className="side_panel_box_centered">
        <ResponsiveYoutube
          title="SongSmith Origins"
          youtubeVideoId="3oGFogwcx-E"
        />
      </div>
      <br />
      <br />
      <p>
        Yes, armed with this pointless software, Tony wasted no time in
        recording his debut album, "I Can't Believe It's Not Music". He then
        forced it upon innocent people in the guise of engagement presents,
        birthday presents, or in some cases without any good cause.
      </p>

      <p>
        One year later, it was time for Tony's second album. Nick Smith, Tony's
        housemate and futue bass player, suggested they use real instruments on
        the album's breakout single, "I Love a Little Irish". Pleased with the
        outcome, and looking for further exposure, they placed the song on{" "}
        <a href="https://www.triplejunearthed.com/artist/tony-besselink">
          Triple J Unearthed
        </a>
        .
      </p>

      <p>
        Eventually Tony was contacted by a lady running a Battle of the Bands
        competition. Sensing a chance to waste more people's time, and eager to
        punish this nice woman for not paying attention to who she was
        contacting, Tony Besselink (The Band) was born.
      </p>

      <img
        className="side_panel_box_centered"
        width="100%"
        src={BandGroupImage}
        alt="A nice shot of the band members"
      />
      <br />
      <br />
      <p>
        Ultimately the plan to waste people's time backfired, as they ended up
        going through two rounds to the state finals, where they came fourth.
        Quite likely the strangest band in Melbourne, or even, dare we say,
        Melbourne + the South East Suburbs, there's no limit to the future
        successes the band might backfire their way into.
      </p>
    </div>
  );
}

export default BandStory;
