/**
 * Blog API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "./ApiClient";
import BlogDto from "./DTOs/BlogDto";

/**
 * Blogs service.
 * @module api/BlogsApi
 * @version v1
 */
export default class BlogsApi {
  /**
   * Constructs a new BlogsApi.
   * @alias module:api/BlogsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the createBlog operation.
   * @callback module:api/BlogsApi~createBlogCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BlogDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/BlogsApi~createBlogCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BlogDto}
   */
  createBlog(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      Published: opts["published"],
      Title: opts["title"],
      Content: opts["content"],
    };

    let authNames = ["Bearer"];
    let contentTypes = ["multipart/form-data"];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = BlogDto;

    return this.apiClient.callApi(
      "/api/blogs",
      "POST",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the deleteBlog operation.
   * @callback module:api/BlogsApi~deleteBlogCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/BlogsApi~deleteBlogCallback} callback The callback function, accepting three arguments: error, data, response
   */
  deleteBlog(blogId, callback) {
    let postBody = null;

    let pathParams = {
      blogId: blogId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/blogs/{blogId}",
      "DELETE",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getBlog operation.
   * @callback module:api/BlogsApi~getBlogCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BlogDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/BlogsApi~getBlogCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BlogDto}
   */
  getBlog(blogId, callback) {
    let postBody = null;

    let pathParams = {
      blogId: blogId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = BlogDto;

    return this.apiClient.callApi(
      "/api/blogs/{blogId}",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getBlogs operation.
   * @callback module:api/BlogsApi~getBlogsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/BlogDto>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/BlogsApi~getBlogsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/BlogDto>}
   */
  getBlogs(opts, callback) {
    opts = opts || {};
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      PublishedMonth: opts["publishedMonth"],
      IncludeUnpublished: opts["includeUnpublished"],
      PageNumber: opts["pageNumber"],
      PageSize: opts["pageSize"],
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = [BlogDto];

    return this.apiClient.callApi(
      "/api/blogs",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the getRandomBlog operation.
   * @callback module:api/BlogsApi~getRandomBlogCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BlogDto} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {module:api/BlogsApi~getRandomBlogCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BlogDto}
   */
  getRandomBlog(callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = [];
    let contentTypes = [];
    let accepts = ["text/plain", "application/json", "text/json"];
    let returnType = BlogDto;

    return this.apiClient.callApi(
      "/api/blogs/random",
      "GET",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
  /**
   * Callback function to receive the result of the updateBlog operation.
   * @callback module:api/BlogsApi~updateBlogCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * @param {Object} opts Optional parameters
   * @param {module:api/BlogsApi~updateBlogCallback} callback The callback function, accepting three arguments: error, data, response
   */
  updateBlog(blogId, opts, callback) {
    opts = opts || {};
    let postBody = opts["body"];

    let pathParams = {
      blogId: blogId,
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ["Bearer"];
    let contentTypes = [
      "application/json-patch+json",
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    let accepts = [];
    let returnType = null;

    return this.apiClient.callApi(
      "/api/blogs/{blogId}",
      "PATCH",
      pathParams,
      queryParams,
      headerParams,
      formParams,
      postBody,
      authNames,
      contentTypes,
      accepts,
      returnType,
      callback
    );
  }
}
