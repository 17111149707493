/**
 * Poll API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserSubmittedReasonJson from './UserSubmittedReasonJson';

/**
* The PollOptionJson model module.
* @module model/PollOptionJson
* @version v1
*/
export default class PollOptionJson {
    /**
    * Constructs a new <code>PollOptionJson</code>.
    * @alias module:model/PollOptionJson
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>PollOptionJson</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PollOptionJson} obj Optional instance to populate.
    * @return {module:model/PollOptionJson} The populated <code>PollOptionJson</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PollOptionJson();
                        
            
            if (data.hasOwnProperty('pollOptionId')) {
                obj['pollOptionId'] = ApiClient.convertToType(data['pollOptionId'], 'Number');
            }
            if (data.hasOwnProperty('pollText')) {
                obj['pollText'] = ApiClient.convertToType(data['pollText'], 'String');
            }
            if (data.hasOwnProperty('numberOfVotes')) {
                obj['numberOfVotes'] = ApiClient.convertToType(data['numberOfVotes'], 'Number');
            }
            if (data.hasOwnProperty('canExplainChoice')) {
                obj['canExplainChoice'] = ApiClient.convertToType(data['canExplainChoice'], 'Boolean');
            }
            if (data.hasOwnProperty('moreInformation')) {
                obj['moreInformation'] = ApiClient.convertToType(data['moreInformation'], 'String');
            }
            if (data.hasOwnProperty('userSubmittedReasons')) {
                obj['userSubmittedReasons'] = ApiClient.convertToType(data['userSubmittedReasons'], [UserSubmittedReasonJson]);
            }
            if (data.hasOwnProperty('voteLink')) {
                obj['voteLink'] = ApiClient.convertToType(data['voteLink'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} pollOptionId
    */
    'pollOptionId' = undefined;
    /**
    * @member {String} pollText
    */
    'pollText' = undefined;
    /**
    * @member {Number} numberOfVotes
    */
    'numberOfVotes' = undefined;
    /**
    * @member {Boolean} canExplainChoice
    */
    'canExplainChoice' = undefined;
    /**
    * @member {String} moreInformation
    */
    'moreInformation' = undefined;
    /**
    * @member {Array.<module:model/UserSubmittedReasonJson>} userSubmittedReasons
    */
    'userSubmittedReasons' = undefined;
    /**
    * @member {String} voteLink
    */
    'voteLink' = undefined;




}
