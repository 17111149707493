import React, { useState, useEffect } from "react";
import BlogsMenuApi from "../../api/BlogsMenuApi";
import { Link } from "react-router-dom";

function BlogMonthMenu(props) {
  const [blogMonths, setBlogMonths] = useState([]);

  useEffect(() => {
    function sortBlogMenuData(data) {
      data.sort((a, b) => b.year - a.year || b.month - a.month);

      let menuMap = new Map();
      data.forEach((monthYear) => {
        if (menuMap.has(monthYear.year)) {
          menuMap
            .get(monthYear.year)
            .push(getMonthDetails(monthYear.month, monthYear.year));
        } else {
          menuMap.set(monthYear.year, [
            getMonthDetails(monthYear.month, monthYear.year),
          ]);
        }
      });

      let menuArray = [];

      menuMap.forEach((monthData, key) => {
        menuArray.push({
          year: key,
          monthData: monthData,
        });
      });
      return menuArray;
    }

    function getMonthDetails(monthNumber, year) {
      let twoDigitMonth = monthNumber.toString().padStart(2, "0");
      let link = `/blog?month=${year}-${twoDigitMonth}`;

      const date = new Date(year, monthNumber - 1, 15); // month number starts at zero
      const monthName = date.toLocaleString("default", { month: "long" });

      return {
        monthNumber: monthNumber,
        monthName: monthName,
        monthLink: link,
      };
    }

    let apiInstance = new BlogsMenuApi();
    apiInstance.getBlogMenu((error, data, response) => {
      if (error) {
        console.error(`We had an error: ${error}`);
      } else {
        let menu = sortBlogMenuData(data);
        setBlogMonths(menu);
      }
    });
  }, []);

  return (
    <>
      <h4>Past Blogs:</h4>
      <ul>
        {blogMonths.map((yearData) => {
          return (
            <li key={yearData.year}>
              {yearData.year}
              <ul>
                {yearData.monthData.map((monthData) => {
                  return (
                    <li key={`${yearData.year}-${monthData.monthNumber}`}>
                      <Link to={monthData.monthLink}>
                        {monthData.monthName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default BlogMonthMenu;
