/**
 * Tony's Music
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The LiveVideoDto model module.
* @module model/LiveVideoDto
* @version v1
*/
export default class LiveVideoDto {
    /**
    * Constructs a new <code>LiveVideoDto</code>.
    * @alias module:model/LiveVideoDto
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LiveVideoDto</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LiveVideoDto} obj Optional instance to populate.
    * @return {module:model/LiveVideoDto} The populated <code>LiveVideoDto</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LiveVideoDto();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('songId')) {
                obj['songId'] = ApiClient.convertToType(data['songId'], 'Number');
            }
            if (data.hasOwnProperty('songName')) {
                obj['songName'] = ApiClient.convertToType(data['songName'], 'String');
            }
            if (data.hasOwnProperty('videoTitle')) {
                obj['videoTitle'] = ApiClient.convertToType(data['videoTitle'], 'String');
            }
            if (data.hasOwnProperty('videoDescription')) {
                obj['videoDescription'] = ApiClient.convertToType(data['videoDescription'], 'String');
            }
            if (data.hasOwnProperty('performanceDate')) {
                obj['performanceDate'] = ApiClient.convertToType(data['performanceDate'], 'Date');
            }
            if (data.hasOwnProperty('youTubeLink')) {
                obj['youTubeLink'] = ApiClient.convertToType(data['youTubeLink'], 'String');
            }
            if (data.hasOwnProperty('displayOrderNumber')) {
                obj['displayOrderNumber'] = ApiClient.convertToType(data['displayOrderNumber'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    'id' = undefined;
    /**
    * @member {String} url
    */
    'url' = undefined;
    /**
    * @member {Number} songId
    */
    'songId' = undefined;
    /**
    * @member {String} songName
    */
    'songName' = undefined;
    /**
    * @member {String} videoTitle
    */
    'videoTitle' = undefined;
    /**
    * @member {String} videoDescription
    */
    'videoDescription' = undefined;
    /**
    * @member {Date} performanceDate
    */
    'performanceDate' = undefined;
    /**
    * @member {String} youTubeLink
    */
    'youTubeLink' = undefined;
    /**
    * @member {Number} displayOrderNumber
    */
    'displayOrderNumber' = undefined;




}
