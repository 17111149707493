import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

function PodcastEpisode(props) {
  return (
    <Card>
      <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey={props.id}>
          <h6 style={{ margin: "0" }}>{props.name}</h6>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={props.id}>
        <Card.Body>
          <div>
            <p style={{ color: "#999", fontSize: "14px" }}><em>{props.publishedDate.toDateString()}</em></p>
            <p style={{ whiteSpace: "pre-line" }}>{props.description}</p>
            <p>
              Listen <a href={props.link} target="_blank" rel="no opener noreferrer">here</a>
            </p>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default PodcastEpisode;
