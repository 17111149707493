import React from "react";
import { Link } from "react-router-dom";

function Fiction(props) {
  return (
    <ul>
      {props.fictions
        .sort((a, b) => b.displayOrder - a.displayOrder)
        .map((fiction) => {
          return (
            <li key={fiction.id}>
              <Link to={`/fiction/${fiction.id}`}> {fiction.title} </Link>
            </li>
          );
        })}
    </ul>
  );
}

export default Fiction;
