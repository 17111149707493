import React from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

function Blog(props) {
  return (
    <>
      <Link to={`/blog/${props.id}`}>
        <h1>{props.title}</h1>
      </Link>
      <p style={{ color: "#999", fontSize: "14px" }}>
        <em>Published {props.publishedDate.toDateString()}</em>
      </p>
      <div className="blogContent">
        <ReactMarkdown>{props.content}</ReactMarkdown>
      </div>
    </>
  );
}

export default Blog;
